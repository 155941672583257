import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  RadioGroup,
  Select,
  Typography,
  Radio,
  Button,
  Stack,
  Divider,
} from '@mui/material'
import {Box} from '@mui/system'
import {EditorSubHeader} from 'edit/editorHeader.widget'
import {EvaluationElementEditor} from 'edit/evaluation/evaluationElementEditor.widget'
import {OmerEdit} from 'edit/evaluation/omerEdit.widget'
import {OperatorBox} from 'edit/evaluation/operatorEdit.widget'
import {ParshaEdit} from 'edit/evaluation/parshaEdit.widget'
import {Evaluation, EvaluationElement, EvaluationField, Operators} from 'models'
import {Parsha} from 'models/parsha.model'
import {isNullOrEmpty, Log} from 'utils'

interface Props {
  evaluation?: Evaluation
  onUpdate: (evaluation?: Evaluation) => void
}

export const EvaluationEditor = ({
  evaluation = {operator: Operators.And},
  onUpdate,
}: Props) => {
  const onOveralChange = (operator: Operators) => {
    onUpdate({...evaluation, operator})
  }

  const onUpdateElement = (index: number) => (element: EvaluationElement) => {
    const copiedElements = evaluation.elements!
    copiedElements[index] = element
    onUpdate({...evaluation, elements: copiedElements})
  }

  const addElement = () => {
    onUpdate({
      ...evaluation,
      elements: [...(evaluation?.elements || []), {operator: Operators.And}],
    })
  }

  const deleteElement = (index: number) => () => {
    const newElements = evaluation.elements!
    newElements.splice(index, 1)
    if (Object.keys(evaluation).length === 2 && isNullOrEmpty(newElements)) {
      onUpdate(undefined)
      return
    }
    onUpdate({...evaluation, elements: newElements})
  }

  const addParsha = () => {
    const newParshios: Parsha[] = [...(evaluation.parsha || []), 'Breishis']
    onUpdate({...evaluation, parsha: newParshios})
  }

  const onParshiosChange = (parshios?: Parsha[]) => {
    onUpdate({...evaluation, parsha: parshios || []})
  }

  const setOmerDay = (omerDay: number[]) => {
    onUpdate({...evaluation, omerDay})
  }

  return (
    <Box>
      <EditorSubHeader name="Evaluation" />
      {!isNullOrEmpty(evaluation.elements) && (
        <OperatorBox
          onChange={onOveralChange}
          operator={evaluation?.operator}
        />
      )}
      {evaluation?.elements?.map((element, index) => (
        <EvaluationElementEditor
          deleteElement={deleteElement(index)}
          element={element}
          onChange={onUpdateElement(index)}
        />
      ))}
      <ParshaEdit parshios={evaluation.parsha} onChange={onParshiosChange} />
      <OmerEdit omerDays={evaluation.omerDay} onChange={setOmerDay} />
      <Stack direction="row">
        <Button variant="text" onClick={addElement}>
          Add Element
        </Button>
        <Button variant="text" onClick={addParsha}>
          Add Parsha
        </Button>
        <Button variant="text" onClick={() => setOmerDay([1])}>
          Add Omer
        </Button>
      </Stack>
    </Box>
  )
}
