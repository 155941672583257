import {Container} from '@mui/material'
import {useLiveProvider} from 'database/live/liveProvider'
import {Section} from 'database/models/section.model'
import {useEditContext} from 'edit/edit.context'
import {EditorHeader} from 'edit/editorHeader.widget'
import {EvaluationEditor} from 'edit/evaluation/evaluationEditor.component'
import {NameEditor} from 'edit/nameEditor.widget'
import {NusachMapEditor} from 'edit/nusachMapEditor.widget'
import {Evaluation} from 'models/evaluation'
import {NusachMap} from 'models/nusach.model'
import {Log} from 'utils'

const TAG = 'sectioneditor'

interface Props {
  sectionKey: string
}

export const SectionEditor = ({sectionKey}: Props) => {
  const {goTo, goBack} = useEditContext()
  const {
    value: section = {key: sectionKey, textGroupIds: {ashkenaz: []}},
    updateValue: updateSection,
  } = useLiveProvider<Section>('sections', sectionKey)

  const onSectionNameChanged = (nameKey: string) => {
    updateSection({...section, name: nameKey})
  }

  const onReorder = (newMap: NusachMap<string[]>) => {
    updateSection({...section, textGroupIds: newMap})
  }

  const onUpdateEvaluation = (evaluation?: Evaluation) => {
    updateSection({...section, evaluation})
  }

  const onTextGroupClicked = (textGroupId: string) => {
    goTo({type: 'textgroup', key: textGroupId})
  }

  return (
    <Container>
      <EditorHeader name={'Section: ' + sectionKey} />
      <NameEditor name={section.name} onNameKeyChange={onSectionNameChanged} />
      <NusachMapEditor
        title="Text Groups"
        nusachMap={section.textGroupIds}
        onValueChange={onReorder}
        onItemClick={onTextGroupClicked}
      />
      <EvaluationEditor
        evaluation={section.evaluation}
        onUpdate={onUpdateEvaluation}
      />
    </Container>
  )
}
