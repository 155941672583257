import {BookKey} from 'books/book.models'
import {TextStyle} from 'database/models/textStyle.model'
import {Evaluation} from 'models/evaluation'
import {Translation} from 'models/translation.model'

export interface TextRefrenceElement {
  book: BookKey
  chapter: number
  startVerse?: number
  endVerse?: number
  breakLineAtVerse: boolean
  evaluation?: Evaluation
  styles?: TextStyle[]
  key: string
  resolvedText: Translation
}

export const isTextReference = (
  element: unknown,
): element is TextRefrenceElement =>
  element !== null &&
  typeof element === 'object' &&
  Object.keys(element!).some((k) => k === 'book')
