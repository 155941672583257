import {Parsha} from 'models/parsha.model'
import {typeguardGenerator} from '../../utils'

export const HebcalParsha = [
  'Bereshit',
  'Noach',
  'Lech-Lecha',
  'Vayera',
  'Chayei Sara',
  'Toldot',
  'Vayetzei',
  'Vayishlach',
  'Vayeshev',
  'Miketz',
  'Vayigash',
  'Vayechi',
  'Shemot',
  'Vaera',
  'Bo',
  'Beshalach',
  'Yitro',
  'Mishpatim',
  'Terumah',
  'Tetzaveh',
  'Ki Tisa',
  'Vayakhel',
  'Pekudei',
  'Vayikra',
  'Tzav',
  'Shmini',
  'Tazria',
  'Metzora',
  'Achrei Mot',
  'Kedoshim',
  'Emor',
  'Behar',
  'Bechukotai',
  'Bamidbar',
  'Nasso',
  "Beha'alotcha",
  "Sh'lach",
  'Korach',
  'Chukat',
  'Balak',
  'Pinchas',
  'Matot',
  'Masei',
  'Devarim',
  'Vaetchanan',
  'Eikev',
  "Re'eh",
  'Shoftim',
  'Ki Teitzei',
  'Ki Tavo',
  'Nitzavim',
  'Vayeilech',
  "Ha'Azinu",
  'End-of-Year',

  'VayakhelPekudei',
  'TazriaMetzora',
  'Achrei MotKedoshim',
  'BeharBechukotai',
  'ChukatBalak',
  'MatotMasei',
  'NitzavimVayeilech',
] as const

export type HebcalParsha = typeof HebcalParsha[number]
export const isHebcalParsha = typeguardGenerator(HebcalParsha)
export const getHebcalParshaForIndex = (index: number) =>
  index < HebcalParsha.length - 1 ? HebcalParsha[index] : undefined
export const getHebcalParshaIndex = (parsha: HebcalParsha) =>
  HebcalParsha.findIndex((p) => p === parsha)

export const ParshiosHebcalParshiosMap = Parsha.reduce((acc, p, i) => {
  acc[HebcalParsha[i]] = p
  return acc
}, {} as Record<HebcalParsha, Parsha>)
