import {Container, Typography} from '@mui/material'
import {useLiveProvider} from 'database/live/liveProvider'

import {useEditContext} from 'edit/edit.context'
import {EditorHeader} from 'edit/editorHeader.widget'
import {EvaluationEditor} from 'edit/evaluation/evaluationEditor.component'
import {NameEditor} from 'edit/nameEditor.widget'
import {NusachMapEditor} from 'edit/nusachMapEditor.widget'
import {Evaluation, NusachMap, Tefila} from 'models'
import {Log} from 'utils'

const TAG = 'TefilaEdit'

interface Props {
  tefilaKey: string
}

export const TefilaEdit = ({tefilaKey}: Props) => {
  const {goTo} = useEditContext()
  const {
    value: tefila = {name: '', sectionIds: {ashkenaz: []}, key: tefilaKey},
    updateValue: updateTefila,
  } = useLiveProvider<any>('tefilot', tefilaKey)

  const onSectionReordered = (newMap: NusachMap<string[]>) => {
    updateTefila({...tefila!, sectionIds: newMap})
  }

  const onTefilaNameKeyChange = (name: string) => {
    updateTefila({...tefila, name})
  }

  const onUpdateEvaluation = (evaluation?: Evaluation) => {
    updateTefila({...tefila, evaluation})
  }

  const onSectionClicked = (sectionKey: string) => {
    goTo({type: 'section', key: sectionKey})
  }

  return (
    <Container>
      <EditorHeader name={'Tefila: ' + tefilaKey} />
      <NameEditor name={tefila.name} onNameKeyChange={onTefilaNameKeyChange} />
      <NusachMapEditor
        title="Sections"
        nusachMap={tefila.sectionIds}
        onValueChange={onSectionReordered}
        onItemClick={onSectionClicked}
      />
      <EvaluationEditor
        evaluation={tefila.evaluation}
        onUpdate={onUpdateEvaluation}
      />
    </Container>
  )
}
