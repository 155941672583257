import {
  WeekDays,
  Holiday,
  Months,
  HallelValues,
} from '../../siddurCalendar/models'
import {Evaluation} from 'models/evaluation/evaluation.model'
import {EvaluationElement} from 'models/evaluation/evaluationElement.model'
import {
  EvaluationField,
  isEvaluationField,
} from 'models/evaluation/evaluationField.model'
import {Nusach} from 'models/nusach.model'
import {Operators} from 'models/evaluation/operator.model'
import {HDateConfig} from 'siddurCalendar/models/dateConfig.model'
import * as Hebcal from 'siddurCalendar/hebrewDate.class'
import {toHebrewMoment} from 'siddurCalendar/hebcal.utils'
import {isAfterSunset} from 'siddurCalendar/zmanim/zman.calculator'
import {arrayOfRange, isNullOrEmpty} from 'utils'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TAG = 'evaluator'

const shacharisKey = 'shacharis'
const minchaKey = 'mincha'
const maarivKey = 'maariv'

export interface EvaluatorConfig {
  hebrewDate: HDateConfig
  nusach: Nusach
  tefila?: string
  runningLate?: boolean
  avel?: boolean
  wedding?: boolean
  sfardAdditions?: boolean
  laterAdditions?: boolean
  originalMinim?: boolean
  alwaysShowMiddleTachanun?: boolean
  birkosHaShacharPolin?: boolean
  bris?: boolean
}

const evaluateParsha = (
  evaluation: Evaluation,
  {hebrewDate}: EvaluatorConfig,
): boolean => {
  if (!evaluation.parsha || evaluation.parsha.length === 0) return true
  return evaluation.parsha.some((p) => p === Hebcal.getParsha(hebrewDate))
}

const evaluateOmerDay = (
  evaluation: Evaluation,
  {hebrewDate}: EvaluatorConfig,
): boolean => {
  if (evaluation.omerDay === undefined) return true
  return evaluation.omerDay.some((d) => d === Hebcal.getOmerDay(hebrewDate))
}

export const evaluate = (
  config: EvaluatorConfig,
  evaluation?: Evaluation,
): boolean => {
  if (!evaluation) return true
  if (!evaluateParsha(evaluation, config)) return false
  if (!evaluateOmerDay(evaluation, config)) return false
  if (isNullOrEmpty(evaluation.elements)) return true
  return evaluation.operator === Operators.And
    ? evaluation.elements!.every((e) => evaluateElement(e, config))
    : evaluation.elements!.some((e) => evaluateElement(e, config))
}

const evaluateElement = (
  element: EvaluationElement,
  config: EvaluatorConfig,
) => {
  const fields = Object.keys(element).filter((k) => k !== 'operator')
  return element.operator === Operators.And
    ? fields.every(
        (field) =>
          !isEvaluationField(field) ||
          evaluateField(field, config) === element[field],
      )
    : fields.some(
        (field) =>
          !isEvaluationField(field) ||
          evaluateField(field, config) === element[field],
      )
}

const fieldEvaluator: Record<
  EvaluationField,
  (config: EvaluatorConfig) => boolean
> = {
  RoshHashana1: ({hebrewDate}) => {
    const {month, day} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 1
  },
  RoshHashana2: ({hebrewDate}) => {
    const {month, day} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 2
  },
  ErevPesach: ({hebrewDate}) =>
    Hebcal.getHolidays(hebrewDate).some((h) => h === Holiday.ErevPesach),
  RoshChodesh: ({hebrewDate}) => Hebcal.isRoshChodesh(hebrewDate),
  Ashkenaz: ({nusach}) => nusach === 'ashkenaz',
  ErevTishaBAv: ({hebrewDate}) =>
    Hebcal.getHolidays(Hebcal.addDays(hebrewDate, 1)).some(
      (h) => h === Holiday.TishaBav,
    ),
  Purim: ({hebrewDate}) => Hebcal.isPurimToday(hebrewDate),
  CandleLighting: ({hebrewDate}) => Hebcal.isCandleLight(hebrewDate),
  Chanuka: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) > 0,
  Chanuka1: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 1,
  Chanuka2: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 2,
  Chanuka3: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 3,
  Chanuka4: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 4,
  Chanuka5: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 5,
  Chanuka6: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 6,
  Chanuka7: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 7,
  Chanuka8: ({hebrewDate}) => Hebcal.getDayOfChanuka(hebrewDate) === 8,
  FullHallel: ({hebrewDate}) =>
    Hebcal.getHallel(hebrewDate) === HallelValues.FullHallel,
  Omer: ({hebrewDate}) => Hebcal.getOmerDay(hebrewDate) > 0,
  Havdalah: ({hebrewDate}) =>
    Hebcal.isYomTov(hebrewDate) ||
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Shabbos,
  PurimKatan: ({hebrewDate}) =>
    Hebcal.getHolidays(hebrewDate).some((h) => h === Holiday.PurimKatan),
  YomHaAtzmaut: ({hebrewDate}) =>
    Hebcal.getHolidays(hebrewDate).some((h) => h === Holiday.YomHaAtzmaut),
  YomHaZikaron: ({hebrewDate}) =>
    Hebcal.getHolidays(hebrewDate).some((h) => h === Holiday.YomHaZikaron),
  YomHaShoah: ({hebrewDate}) =>
    Hebcal.getHolidays(hebrewDate).some((h) => h === Holiday.YomHaShoah),
  HakamasHaMishkan: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day <= 12
  },
  HakamasHaMishkan1: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 1
  },
  HakamasHaMishkan2: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 2
  },
  HakamasHaMishkan3: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 3
  },
  HakamasHaMishkan4: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 4
  },
  HakamasHaMishkan5: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 5
  },
  HakamasHaMishkan6: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 6
  },
  HakamasHaMishkan7: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 7
  },
  HakamasHaMishkan8: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 8
  },
  HakamasHaMishkan9: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 9
  },
  HakamasHaMishkan10: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 10
  },
  HakamasHaMishkan11: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 11
  },
  HakamasHaMishkan12: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day === 12
  },
  LeilBedika: ({hebrewDate}) =>
    Hebcal.isErevPesach(Hebcal.addDays(hebrewDate, 1)),
  Pesach: ({hebrewDate}) => Hebcal.isPesach(hebrewDate),
  Pesach1: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 15,
  Pesach2: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 16,
  Pesach3: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 17,
  Pesach4: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 18,
  Pesach5: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 19,
  Pesach6: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 20,
  Pesach7: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 21,
  Pesach8: ({hebrewDate}) =>
    Hebcal.isPesach(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 22,
  Mussaf: ({hebrewDate}) => Hebcal.isMussaf(hebrewDate),
  ErevChanuka: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Kislev && day === 24
  },
  KirasHaTorah: ({hebrewDate}) =>
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Monday ||
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Thursday ||
    Hebcal.isMussaf(hebrewDate) ||
    Hebcal.getHolidays(hebrewDate).some((h) => h === Holiday.Chanukah) ||
    Hebcal.isPurimToday(hebrewDate) ||
    Hebcal.isFastDay(hebrewDate),
  Hallel: ({hebrewDate}) =>
    [HallelValues.FullHallel, HallelValues.HalfHallel].some(
      (h) => h === Hebcal.getHallel(hebrewDate),
    ),
  EiruvTavshilin: ({hebrewDate}) => Hebcal.isEruvTavshilin(hebrewDate),
  YomYerushaleim: ({hebrewDate}) =>
    Hebcal.getHolidays(hebrewDate).some((h) => h === Holiday.YomYerushaleim),
  YaaleVYavo: ({hebrewDate}) => Hebcal.isMussaf(hebrewDate),
  IsShabbos: ({hebrewDate}) =>
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Shabbos,
  Tachanun: ({hebrewDate, bris, wedding, avel}) =>
    !bris && !avel && !wedding && Hebcal.isTachanunShacharis(hebrewDate),
  TachanunDate: ({hebrewDate}) => Hebcal.isTachanunShacharis(hebrewDate),
  MinchaTachanun: ({hebrewDate, avel, bris, wedding}) =>
    !bris && !avel && !wedding && Hebcal.isTachanunMincha(hebrewDate),
  MiddleTachanun: ({hebrewDate, alwaysShowMiddleTachanun}) =>
    alwaysShowMiddleTachanun || Hebcal.isFastDay(hebrewDate),
  MinchaTachanunDate: ({hebrewDate}) => Hebcal.isTachanunMincha(hebrewDate),
  LongTachanun: ({hebrewDate}) =>
    [WeekDays.Thursday, WeekDays.Monday].some(
      (d) => d === Hebcal.getDayOfWeek(hebrewDate),
    ) && Hebcal.isTachanunShacharis(hebrewDate),
  Lamnatzeach: ({hebrewDate, avel}) => {
    const omitHolidays: Holiday[] = [
      Holiday.Chanukah,
      Holiday.ErevPesach,
      Holiday.TishaBav,
      Holiday.ErevYomKippur,
      Holiday.Purim,
      Holiday.ShushanPurim,
      Holiday.PurimKatan,
      Holiday.YomHaAtzmaut,
      Holiday.YomYerushaleim,
    ]
    return (
      !Hebcal.isMussaf(hebrewDate) &&
      !avel &&
      !Hebcal.getHolidays(hebrewDate).some((h) => omitHolidays.includes(h))
    )
  },
  Mincha: ({tefila}) => tefila === minchaKey,
  Maariv: ({tefila}) => tefila === maarivKey,
  Shacharis: ({tefila}) => tefila === shacharisKey,
  BirkasLevana: ({hebrewDate}) => Hebcal.isBirkasLevana(hebrewDate),
  FastDay: ({hebrewDate}) => Hebcal.isFastDay(hebrewDate),
  IsSummer: ({hebrewDate}) => Hebcal.isSummer(hebrewDate),
  IsSummerNew: ({hebrewDate}) => Hebcal.isSummerNew(hebrewDate),
  IsRain: ({tefila, hebrewDate}) =>
    tefila === maarivKey
      ? Hebcal.isRain(Hebcal.addDays(hebrewDate, 1))
      : Hebcal.isRain(hebrewDate),
  IsRainNew: ({hebrewDate, tefila}) =>
    tefila === maarivKey
      ? Hebcal.isRainNew(Hebcal.addDays(hebrewDate, 1))
      : Hebcal.isRainNew(hebrewDate),
  IsYomTov: ({hebrewDate}) => Hebcal.isYomTov(hebrewDate),
  VayitenLecha: ({hebrewDate}) =>
    arrayOfRange(0, 7).some((add) => {
      const searchDate = Hebcal.addDays(hebrewDate, add)
      return Hebcal.isYomTov(searchDate) || Hebcal.isErevPesach(searchDate)
    }),
  Motzash: ({hebrewDate}) =>
    (Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Sunday &&
      !isAfterSunset(hebrewDate)) ||
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Shabbos,
  LagBomer: ({hebrewDate}) => Hebcal.getOmerDay(hebrewDate) === 33,
  Shavuos: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.Shavuos),
  Shavuos1: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.Shavuos) &&
    toHebrewMoment(hebrewDate.date).day === 6,
  Shavuos2: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.Shavuos) &&
    toHebrewMoment(hebrewDate.date).day === 7,
  TzomTaamuz: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.TzomTaamuz),
  NineDays: ({hebrewDate}) =>
    (toHebrewMoment(hebrewDate.date).month === Months.Av &&
      toHebrewMoment(hebrewDate.date).day < 10) ||
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.TishaBav),
  TishaBAv: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.TishaBav),
  TuBAv: ({hebrewDate}) =>
    toHebrewMoment(hebrewDate.date).month === Months.Av &&
    toHebrewMoment(hebrewDate.date).day === 15,
  Ldovid: ({hebrewDate}) =>
    toHebrewMoment(hebrewDate.date).month === Months.Elul ||
    (toHebrewMoment(hebrewDate.date).month === Months.Tishrei &&
      toHebrewMoment(hebrewDate.date).day < 22),
  ErevRoshHaShana: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.ErevRoshHashana),
  RoshHashana: ({hebrewDate}) => Hebcal.isRoshHashana(hebrewDate),
  EseresYemeiTeshuva: ({hebrewDate}) => Hebcal.isEseresYemeiTeshuva(hebrewDate),
  Tashlich: ({hebrewDate}) => {
    const {day, month} = toHebrewMoment(hebrewDate.date)
    return month === Months.Tishrei && day < 21
  },
  TzomGedalia: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.TzomGedaliah),
  ErevYomKippur: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.ErevYomKippur),
  YomKippur: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.YomKippur),
  Succos1: ({hebrewDate}) =>
    Hebcal.isSuccos(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 15,
  Succos2: ({hebrewDate}) =>
    Hebcal.isSuccos(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 16,
  Succos3: ({hebrewDate}) =>
    Hebcal.isSuccos(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 17,
  Succos4: ({hebrewDate}) =>
    Hebcal.isSuccos(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 18,
  Succos5: ({hebrewDate}) =>
    Hebcal.isSuccos(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 19,
  Succos6: ({hebrewDate}) =>
    Hebcal.isSuccos(hebrewDate) && toHebrewMoment(hebrewDate.date).day === 20,
  Succos: ({hebrewDate}) => Hebcal.isSuccos(hebrewDate),
  HoshanaRaba: ({hebrewDate}) =>
    toHebrewMoment(hebrewDate.date).month === Months.Tishrei &&
    toHebrewMoment(hebrewDate.date).day === 21,
  SimchasTorah: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.SimchasTorah),
  SheminiAtzeres: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.ShminiAtzeres),
  TzomTevs: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.TzomTeives),
  TaanisEsther: ({hebrewDate}) =>
    !!Hebcal.getHolidays(hebrewDate).find((h) => h === Holiday.TaanisEsther),
  Sunday: ({hebrewDate}) => Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Sunday,
  Monday: ({hebrewDate}) => Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Monday,
  Tuesday: ({hebrewDate}) =>
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Tuesday,
  Wednesday: ({hebrewDate}) =>
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Wednsday,
  Thursday: ({hebrewDate}) =>
    Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Thursday,
  Friday: ({hebrewDate}) => Hebcal.getDayOfWeek(hebrewDate) === WeekDays.Friday,
  HebrewLeapYear: ({hebrewDate}) => Hebcal.isHebrewLeapYear(hebrewDate.date),
  Sfard: ({nusach}) => nusach === 'sfard',
  Mizrach: ({nusach}) => nusach === 'mizrach',
  InIsrael: ({hebrewDate}) => hebrewDate.inIsrael,
  InJerusalem: ({hebrewDate}) => hebrewDate.inJerusalem,
  LaterAdditions: ({laterAdditions}) => !!laterAdditions,
  SfardAdditions: ({sfardAdditions}) => !!sfardAdditions,
  OriginalMinim: ({originalMinim}) => !!originalMinim,
  BirkosHaShacharPolin: ({birkosHaShacharPolin}) => !!birkosHaShacharPolin,
  Wedding: ({wedding}) => !!wedding,
  Bris: ({bris}) => !!bris,
  Avel: ({avel}) => !!avel,
  RunningLate: ({runningLate}) => !!runningLate,
}

const evaluateField = (field: EvaluationField, config: EvaluatorConfig) =>
  fieldEvaluator[field](config)
