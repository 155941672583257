import {useFirebase} from 'firebase/firebaseRoot'
import {ref, onValue} from 'firebase/database'
import {filterNonNull, Log} from 'utils'
import {useEffect, useState} from 'react'
import {getPathForType} from 'database/dbUtils'
import {BookKey} from 'books/book.models'
import {Language, Translation} from 'models'

const TAG = 'TanachProvider'

export const useTanach = <
  T extends Extract<Language, 'english' | 'transliterated'>,
>(
  ...languages: T[]
) => {
  const {db} = useFirebase()
  const [book, setBook] = useState<Translation[][]>([])
  const [bookKey, setBookKey] = useState<BookKey>()

  useEffect(() => {
    if (!db) return
    const searchLanguages: Language[] = [...languages, 'hebrew']
    Promise.all(
      searchLanguages.map(
        (language) =>
          new Promise<{book: string[][]; language: Language} | undefined>(
            (resolve) =>
              onValue(
                ref(
                  db,
                  getPathForType('tanach') + '/' + language + '/' + bookKey,
                ),
                (snapshot) => {
                  if (snapshot.exists()) {
                    const val = snapshot.val()
                    resolve({book: val as string[][], language})
                  } else {
                    resolve(undefined)
                  }
                },
                {
                  onlyOnce: true,
                },
              ),
          ),
      ),
    ).then((value) => {
      const reduced = filterNonNull(value).reduce(
        (acc, val) => {
          const bookValue = val.book
          Log.d(TAG, bookValue)
          bookValue.forEach((chapter, index) => {
            acc[index] = chapter.map((verse, i) => {
              const translationObj: Translation = (acc[index] || [])[i] || {
                hebrew: '',
              }
              translationObj[val.language] = verse
              return translationObj
            })
            return acc
          })
          return acc
        },
        [[{hebrew: ''}]] as Translation[][],
      )
      setBook(reduced || [[{hebrew: ''}]])
    })
  }, [db, bookKey])

  return {book, setBookKey}
}
