import {Container, Typography} from '@mui/material'
import {SxProps, Theme} from '@mui/system'
import {useFirebaseDb} from 'database/firebaseProvider'
import {useEditContext} from 'edit/edit.context'
import {
  getTranslatedValue,
  getValueForNusach,
  StyleTypes,
  Tefila,
  TextStyle,
} from 'models'
import React, {useEffect, useMemo, useState} from 'react'
import {useSiddurContext} from 'siddurContext/siddurRoot'
import {filterNonNull, isNullOrEmpty, Log} from 'utils'

const TAG = 'TefilaPresenter'

export const TefilaPresenter = () => {
  const {tefilos, watchTefila, sections, textGroups, textElements} =
    useFirebaseDb()
  const {nusach, tefilaKey, evaluate, language} = useSiddurContext()
  const [tefila, setTefila] = useState<Tefila>()

  useEffect(() => {
    setTefila(tefilos.find(({key}) => key === tefilaKey))
  }, [tefilaKey, tefilos])

  useEffect(() => {
    if (tefila) {
      watchTefila(tefila)
    }
  }, [tefila, nusach])

  const data = useMemo(() => {
    return filterNonNull(
      getValueForNusach(nusach, tefila?.sectionIds)?.map((id) =>
        sections.find((s) => s.key === id),
      ),
    )
      .filter((s) => evaluate(s.evaluation))
      .reduce((acc, section) => {
        const name = (
          <Typography dir="auto" variant="subtitle2">
            {section.resolvedName
              ? getTranslatedValue(section.resolvedName!, language)
              : ''}
          </Typography>
        )
        const text = filterNonNull(
          getValueForNusach(nusach, section.textGroupIds)?.map((id) =>
            textGroups.find(({key}) => key === id),
          ),
        )
          .filter((g) => evaluate(g.evaluation))
          .map((group) =>
            filterNonNull(
              getValueForNusach(nusach, group.elements)?.map((textElement) =>
                textElements.find(({key}) => key === textElement),
              ),
            )
              .filter((e) => evaluate(e.evaluation))
              .map((element) => {
                const allStyle = (element.styles || [])
                  .filter(({position}) => position === 'All')
                  .reduce((styleAcc, s) => {
                    return {...styleAcc, ...styleForStyle(s)}
                  }, {} as SxProps<Theme>)
                const spanStyle = {color: 'green'}
                const baseText = getTranslatedValue(
                  element.resolvedText,
                  language,
                )
                return (
                  <Typography variant="body2" sx={allStyle}>
                    <span style={spanStyle}></span>
                    {getTranslatedValue(element.resolvedText, language)}
                  </Typography>
                )
              }),
          )
        return [...acc, name, ...text]
      }, [] as React.ReactNode[])
  }, [nusach, tefila, sections, textGroups, textElements])
  return <Container>{data.map((d) => d)}</Container>
}

const styleStyles: Record<StyleTypes, SxProps<Theme>> = {
  Bold: {fontWeight: 'Bold'},
  Large: {fontSize: 28},
  Small: {fontSize: 16},
  SpecialColor: {color: 'primary.main'},
}

const styleForStyle = (textStyle: TextStyle) => {
  return styleStyles[textStyle.name]
}
