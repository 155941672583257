export const TypeFace = [
  'Frank',
  'David',
  'Ezra',
  'Hadasim',
  'Mekorot Vilna',
] as const
export type TypeFace = typeof TypeFace[number]
// export const isTypeFace = typeguardGenerator(TypeFace)

const iOSFontFace: Record<TypeFace, string> = {
  Frank: 'Taamey Frank CLM',
  'Mekorot Vilna': 'Mekorot-Vilna',
  Ezra: 'Ezra SIL SR',
  Hadasim: 'Hadasim CLM',
  David: 'David CLM',
}
const androidFontFace: Record<TypeFace, string> = {
  Frank: 'frank',
  'Mekorot Vilna': 'vilna',
  Ezra: 'ezra',
  Hadasim: 'hadassim',
  David: 'david',
}

const displayNames: Record<TypeFace, string> = TypeFace.reduce(
  (names, name) => {
    names[name] = name
    return names
  },
  {} as Record<TypeFace, string>,
)

export const getFontFamilyName = (typeface: TypeFace) => iOSFontFace[typeface]

export const getTypefaceDisplayName = (typeface: TypeFace) =>
  displayNames[typeface]
