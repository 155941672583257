import React, {useContext, useState} from 'react'
import {removeLastItem} from 'utils'

interface Props {
  children: React.ReactNode
}

export const EditType = [
  'category',
  'tefila',
  'section',
  'textgroup',
  'textelement',
  'text',
] as const
export type EditType = typeof EditType[number]
interface EditorPageConfig {
  type: EditType
  key: string
}
interface EditContext {
  breadcrumbs: EditorPageConfig[]
  editEnabled: boolean
  setEditEnabeld: (enabled: boolean) => void
  setBreadcrumbs: (crumbs: EditorPageConfig[]) => void
  goTo: (config: EditorPageConfig, reset?: boolean) => void
  goBack: () => void
}

const contextValue: EditContext = {
  breadcrumbs: [],
  editEnabled: false,
  setEditEnabeld: () => {},
  setBreadcrumbs: () => {},
  goTo: () => {},
  goBack: () => {},
}

const EditContext = React.createContext<EditContext>(contextValue)

export const EditRoot = ({children}: Props) => {
  const [editEnabled, setEditEnabeld] = useState(false)
  const [breadcrumbs, setBreadcrumbs] = useState<EditorPageConfig[]>([])

  const goTo = (config: EditorPageConfig, reset = false) => {
    reset ? setBreadcrumbs([config]) : setBreadcrumbs((bc) => [...bc, config])
  }

  const goBack = () => {
    setBreadcrumbs((bc) => removeLastItem(bc) || [])
  }

  return (
    <EditContext.Provider
      value={{
        breadcrumbs,
        goTo,
        goBack,
        setBreadcrumbs,
        editEnabled,
        setEditEnabeld,
      }}
    >
      {children}
    </EditContext.Provider>
  )
}

export const useEditContext = () => useContext(EditContext)
