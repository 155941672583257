import React, {useContext, useEffect, useState} from 'react'
import {FirebaseApp, initializeApp} from 'firebase/app'
import {Auth, getAuth, User} from 'firebase/auth'
import {Database, getDatabase} from 'firebase/database'

const firebaseConfig = {
  apiKey: 'AIzaSyAgobJcQexMlZjGmR8qptZanZhKJFrYrt8',
  authDomain: 'directed-sensor-780.firebaseapp.com',
  databaseURL: 'https://directed-sensor-780.firebaseio.com',
  projectId: 'directed-sensor-780',
  storageBucket: 'directed-sensor-780.appspot.com',
  messagingSenderId: '200656089391',
  appId: '1:200656089391:web:350677e3b45a2a79ce71da',
}

const firebaseApp = initializeApp(firebaseConfig)
const auth = getAuth(firebaseApp)
const firebaseDb = getDatabase(firebaseApp)
interface Props {
  children: React.ReactNode
}

interface FirebaseContextValue {
  firebaseApp: FirebaseApp
  auth: Auth
  db: Database
  firebaseUser?: User
}

const contextValue: FirebaseContextValue = {firebaseApp, auth, db: firebaseDb}

const FirebaseContext = React.createContext<FirebaseContextValue>(contextValue)

export const FirebaseRoot = ({children}: Props) => {
  const [firebaseUser, setFirebaseUser] = useState<User>()

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setFirebaseUser(user ? user : undefined)
    })
    return unsub
  }, [])

  return (
    <FirebaseContext.Provider value={{...contextValue, firebaseUser}}>
      {children}
    </FirebaseContext.Provider>
  )
}

export const useFirebase = () => useContext(FirebaseContext)
