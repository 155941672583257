import {User} from 'auth/user.model'
import {useFirebase} from 'firebase/firebaseRoot'
import {useEffect, useState} from 'react'
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth'

const googleProvider = new GoogleAuthProvider()
googleProvider.addScope('profile')
googleProvider.addScope('email')

export const useAuth = () => {
  const {auth, firebaseUser} = useFirebase()
  const [user, setUser] = useState<User>()

  const signInWithGoogle = () => signInWithPopup(auth, googleProvider)

  const signOut = () => auth.signOut()

  useEffect(() => {
    if (firebaseUser) {
      const {photoURL, email, displayName, uid} = firebaseUser
      setUser({
        name: displayName || 'anon',
        email: email || undefined,
        avatarUrl: photoURL || undefined,
        id: uid,
      })
    }
    if (!firebaseUser) setUser(undefined)
  }, [firebaseUser])

  return {user, signInWithGoogle, signOut}
}
