import moment from 'moment'
import {toJSDate, toMoment} from 'siddurCalendar/hebcal.utils'
import {Zman, ZmanValue} from './zman.model'
import {getTimes, addTime} from 'suncalc'
import {HDateConfig} from 'siddurCalendar/models/dateConfig.model'

addTime(-16.1, Zman.AlosHaShachar, Zman.TzeisRT)
addTime(-11.5, Zman.MiSheyakir, 'ignore')
addTime(-6, 'ignore', Zman.TzeisEarly)
addTime(-6.45, 'ignore', Zman.SiyumTzom)
addTime(-7.12, 'ignore', Zman.SiyumTzomRavMoshe)
addTime(-8.5, 'ignore', Zman.Tzeis)

const memoizedKey = (config: HDateConfig) =>
  toMoment(config.date).valueOf() +
  config.location.latitude +
  config.location.longitude
let memoizedSunTimes = {}

const getRootZemanim = (config: HDateConfig) => {
  const key = memoizedKey(config)
  if (!memoizedSunTimes[key]) {
    memoizedSunTimes[key] = getTimes(
      toJSDate(config.date),
      config.location.latitude,
      config.location.longitude,
    )
  }
  return memoizedSunTimes[key]
}

export const getAlos = (config: HDateConfig) =>
  moment(getRootZemanim(config)[Zman.AlosHaShachar])
export const getMishkyakir = (config: HDateConfig) =>
  moment(getRootZemanim(config)[Zman.MiSheyakir])
export const getTzeisRT = (config: HDateConfig) =>
  moment(getRootZemanim(config)[Zman.TzeisRT])
export const getTzeis = (config: HDateConfig) =>
  moment(getRootZemanim(config)[Zman.Tzeis])
export const getNeitz = (config: HDateConfig) =>
  moment(getRootZemanim(config).sunrise)
export const getShkiya = (config: HDateConfig) =>
  moment(getRootZemanim(config).sunset)

export const getAlosFixedMinutes = (config: HDateConfig) =>
  getNeitz(config).add(-72, 'minutes')
export const getTzaisRTFixedMinutes = (config: HDateConfig) =>
  getShkiya(config).add(72, 'minutes')
export const getTzaisEarly = (config: HDateConfig) =>
  moment(getRootZemanim(config)[Zman.TzeisEarly])
export const getSiyumHaTzom = (config: HDateConfig) =>
  moment(getRootZemanim(config)[Zman.SiyumTzom])
export const getSiyumHaTzomRM = (config: HDateConfig) =>
  moment(getRootZemanim(config)[Zman.SiyumTzomRavMoshe])

const getShaaZmanisGra = (config: HDateConfig) => {
  const sunrise = getNeitz(config)
  const sunset = getShkiya(config)
  return sunset.diff(sunrise, 'millisecond') / 12
}

const addShaaZmanis = (shaaZmanios: number, config: HDateConfig) => {
  const shaaZmanisMilis = getShaaZmanisGra(config)
  return getNeitz(config).add(shaaZmanisMilis * shaaZmanios, 'milliseconds')
}

export const getSofZmanShma = (config: HDateConfig) => addShaaZmanis(3, config)

export const getSofZmanTefila = (config: HDateConfig) =>
  addShaaZmanis(4, config)

export const getChatzosHayom = (config: HDateConfig) => addShaaZmanis(6, config)

export const getMinchaGedola = (config: HDateConfig) =>
  addShaaZmanis(6.5, config)

export const getMinchaKetana = (config: HDateConfig) =>
  addShaaZmanis(9.5, config)

export const getPlagHaMincha = (config: HDateConfig) =>
  addShaaZmanis(10.75, config)

export const getCandleLight = (config: HDateConfig) =>
  getShkiya(config).add(-config.candleLightOffset, 'minutes')

export const getChatzosHaLaila = (config: HDateConfig) =>
  getChatzosHayom(config).add(12, 'hours')

export const isAfterSunset = (config: HDateConfig) =>
  moment().isAfter(getShkiya(config))

export const zmanValues: Record<Zman, (config: HDateConfig) => moment.Moment> =
  {
    alot_hashachaar: getAlos,
    misheyakir: getMishkyakir,
    neitz_hachama: getNeitz,
    sof_zman_shma: getSofZmanShma,
    sof_zman_tfilla: getSofZmanTefila,
    chatzot: getChatzosHayom,
    mincha_gedola: getMinchaGedola,
    mincha_ketana: getMinchaKetana,
    plag_hamincha: getPlagHaMincha,
    candle_light: getCandleLight,
    shkiah: getShkiya,
    tzeis_early: getTzaisEarly,
    siyum_tzom: getSiyumHaTzom,
    siyum_tzom_rav_moshe: getSiyumHaTzomRM,
    tzeit: getTzeis,
    tzeis_rt: getTzeisRT,
    chatzot_night: getChatzosHaLaila,
  }

export const getValuesForZmanim = (zmanim: Zman[], config: HDateConfig) =>
  zmanim
    .reduce(
      (acc, zman) => [...acc, {key: zman, date: zmanValues[zman](config)}],
      [] as ZmanValue[],
    )
    .sort((a, b) => a.date.valueOf() - b.date.valueOf())
