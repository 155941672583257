import {
  HDate,
  HebrewCalendar,
  DafYomi,
  Sedra,
  gematriya,
  Locale,
  Event,
} from '@hebcal/core'
import {
  Holiday,
  WeekDays,
  Months,
  HallelValues,
  HebrewMoment,
  isHebrewMoment,
  getNameForMonth,
  HebcalHoliday,
  ParshiosHebcalParshiosMap,
  hebcalHolidayToHoliday,
  Zman,
} from './models/index'
import {
  formatGematriya,
  HebrewLocale,
  toHebcalDate,
  toHebrewMoment,
  toJSDate,
  toMoment,
} from './hebcal.utils'

import {Parsha} from 'models/parsha.model'
import {filterNonNull} from 'utils'
import {DateValue, HDateConfig} from 'siddurCalendar/models/dateConfig.model'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TAG = 'HebrewDate'

const areSameDate = (a: HebrewMoment, b: HebrewMoment) =>
  a.day === b.day && a.month === b.month

export const addDays = (config: HDateConfig, days: number): HDateConfig => ({
  ...config,
  date: toMoment(config.date).add(days, 'days'),
})

export const addYears = (config: HDateConfig, years: number): HDateConfig => ({
  ...config,
  date: toMoment(config.date).add(years, 'years'),
})

export const isSameHebrewDate = (date: DateValue, compare: DateValue) =>
  areSameDate(toHebrewMoment(compare), toHebrewMoment(date))

export const getHolidayForEvent = (event: Event) =>
  hebcalHolidayToHoliday(
    event
      .getDesc()
      .replace(/\(observed\)/, '')
      .replace(/\d\d\d\d/, '')
      .trim() as HebcalHoliday,
  )

export const hebcalEventsToHolidays = (events: Event[]) =>
  filterNonNull(events.map(getHolidayForEvent))

export const getHolidays = ({date, inIsrael}: HDateConfig): Holiday[] => {
  const events =
    HebrewCalendar.getHolidaysOnDate(toJSDate(date), inIsrael) || []
  return hebcalEventsToHolidays(events)
}
export const getHebrewHolidays = ({date, inIsrael}: HDateConfig): string[] => {
  const events =
    HebrewCalendar.getHolidaysOnDate(toJSDate(date), inIsrael) || []

  return events.map((event) => event.renderBrief(HebrewLocale))
}

export const getZmanimForDay = (config: HDateConfig): Zman[] => {
  let zmanim: Zman[] = [
    Zman.AlosHaShachar,
    Zman.MiSheyakir,
    Zman.HaNeitzHaChama,
    Zman.SofZmanShma,
    Zman.SofZmanTefila,
    Zman.Chatzos,
    Zman.MinchaGedola,
    Zman.MinchaKetana,
    Zman.PlagHaMincha,
    Zman.Shkiah,
    Zman.TzeisEarly,
    Zman.Tzeis,
    Zman.TzeisRT,
    Zman.ChatzosHaLaila,
  ]
  if (isCandleLight(config)) {
    zmanim.push(Zman.HadlakasNeiros)
  }
  if (isFastDay(config)) {
    zmanim.push(Zman.SiyumTzom)
    zmanim.push(Zman.SiyumTzomRavMoshe)
  }

  return zmanim
}

export const getHebrewYear = ({date}: HDateConfig) =>
  isHebrewMoment(date) && date.year
    ? date.year
    : toHebrewMoment(toJSDate(date)).year!

export const getYearHolidays = (config: HDateConfig) => {
  return HebrewCalendar.calendar({
    year: getHebrewYear(config),
    isHebrewYear: true,
    il: config.inIsrael,
    candleLightingMins: config.candleLightOffset,
  })
}

export const getDafYomi = ({date}: HDateConfig) => {
  const daf = new DafYomi(toJSDate(date))
  return `${Locale.gettext(daf.getName(), HebrewLocale)} ${formatGematriya(
    daf.getBlatt(),
  )}`
}

export const getParsha = (config: HDateConfig): Parsha => {
  const sedra = new Sedra(getHebrewYear(config), config.inIsrael)
  let searchDate: DateValue = toHebrewMoment(config.date)
  let found = sedra.lookup(toHebcalDate(searchDate))
  while (found.chag) {
    searchDate = addDays({...config, date: searchDate}, 7).date
    found = sedra.lookup(toHebcalDate(searchDate))
  }
  const parsha: Parsha = ParshiosHebcalParshiosMap[found.parsha.join('')]
  return parsha === 'Breishis' && toHebrewMoment(config.date).day < 23
    ? 'VezosHaBrocha'
    : parsha
}
export const isYomYerusahleim = (config: HDateConfig) => {
  return getHolidays(config).some((h) => h === Holiday.YomYerushaleim)
}

export const isYomHaatzmaut = (config: HDateConfig) => {
  return getHolidays(config).some((h) => h === Holiday.YomHaAtzmaut)
}

export const isEruvTavshilin = (config: HDateConfig) => {
  const {month, day: dayOfMonth} = toHebrewMoment(config.date)
  const dayOfWeek = toMoment(config.date).weekday()
  const holidays = getHolidays(config)
  if (
    [
      Months.Eyar,
      Months.Taamuz,
      Months.Av,
      Months.Cheshvan,
      Months.Kislev,
      Months.Teves,
      Months.Shvat,
      Months.Adar,
      Months.AdarII,
    ].some((m) => m === month)
  )
    return false
  if (![WeekDays.Thursday, WeekDays.Wednsday].some((d) => d === dayOfWeek))
    return false
  if (dayOfMonth === 9) return false
  if (holidays.some((h) => h === Holiday.ErevRoshHashana)) return true
  if (config.inIsrael) {
    return isYomTov(addDays(config, 1)) && dayOfWeek === WeekDays.Thursday
  }
  return isYomTov(addDays(config, 1))
}

export const getHallel = (config: HDateConfig): HallelValues => {
  if (
    isYomHaatzmaut(config) ||
    isYomYerusahleim(config) ||
    isSuccos(config) ||
    isChanuka(config)
  )
    return HallelValues.FullHallel
  if (isRoshChodesh(config) || isPesach(config)) return HallelValues.HalfHallel

  return HallelValues.NoHallel
}

const baseTachanun = (config: HDateConfig) => {
  if (isMussaf(config)) return false
  if (getHallel(config) !== HallelValues.NoHallel) return false
  const {month, day} = toHebrewMoment(config.date)
  if (month === Months.Nissan) return false
  if (month === Months.Sivan && day < 14) return false
  if (month === Months.Tishrei && day > 10) return false
  return true
}

export const isTachanunShacharis = (config: HDateConfig): boolean => {
  if (!baseTachanun(config)) return false
  const omitOnHolidays: Holiday[] = [
    Holiday.LagBomer,
    Holiday.ErevRoshHashana,
    Holiday.TuBsvhat,
    Holiday.PesachSheini,
    Holiday.Purim,
    Holiday.ShushanPurim,
    Holiday.TishaBav,
    Holiday.YomYerushaleim,
    Holiday.YomHaAtzmaut,
    Holiday.LagBomer,
    Holiday.PurimKatan,
  ]
  if (getHolidays(config).some((h) => omitOnHolidays.includes(h))) return false
  return true
}
export const isTachanunMincha = (config: HDateConfig): boolean => {
  if (!baseTachanun(config)) return false
  if (isCandleLight(config)) return false
  const omitOnHolidays: Holiday[] = [
    Holiday.LagBomer,
    Holiday.TuBsvhat,
    Holiday.PesachSheini,
    Holiday.Purim,
    Holiday.ShushanPurim,
    Holiday.TishaBav,
    Holiday.LagBomer,
    Holiday.PurimKatan,
    Holiday.Chanukah,
  ]
  if (getHolidays(addDays(config, 1)).some((h) => omitOnHolidays.includes(h)))
    return false
  return true
}

export const isRain = (config: HDateConfig) => {
  return config.inIsrael ? isIsraelRain(config) : isRainChul(config)
}

export const isRainNew = (config: HDateConfig) =>
  isRain(config) !== isRain(addDays(config, -30))

export const isBirkasLevana = (config: HDateConfig) => {
  const {day} = toHebrewMoment(config.date)
  //TODO this is naive
  return day > 3 && day < 15
}

export const isSummer = (config: HDateConfig): boolean => {
  const summerMonths: Months[] = [
    Months.Eyar,
    Months.Sivan,
    Months.Taamuz,
    Months.Av,
    Months.Elul,
  ]
  const winterMonths: Months[] = [
    Months.Cheshvan,
    Months.Kislev,
    Months.Teves,
    Months.Shvat,
    Months.Adar,
    Months.AdarII,
  ]
  const {month, day} = toHebrewMoment(config.date)

  if (summerMonths.some((m) => m === month)) return true
  if (winterMonths.some((m) => m === month)) return false

  if (month === Months.Tishrei) return day < 22
  if (month === Months.Nissan) return day > 14

  return false
}

export const isSummerNew = (config: HDateConfig): boolean =>
  isSummer(config) !== isSummer(addDays(config, -30))

export const isRainChul = (config: HDateConfig): boolean => {
  const {month, day} = toHebrewMoment(config.date)
  const gregorian = toMoment(config.date)
  const gregorianMonth = gregorian.month()
  const gregorianDay = gregorian.date()
  return (
    ([0, 1, 2, 3].some((m) => m === gregorianMonth) &&
      [
        Months.Cheshvan,
        Months.Kislev,
        Months.Teves,
        Months.Shvat,
        Months.Adar,
        Months.AdarII,
      ].some((m) => month === m)) ||
    (month === Months.Nissan && day < 15) ||
    (gregorianMonth === 11 && gregorianDay > firstDayOfChulRain(config))
  )
}

export const firstDayOfChulRain = (config: HDateConfig): number =>
  isNextGregorianYearLeap(config) ? 5 : 4

export const isNextGregorianYearLeap = (config: HDateConfig) =>
  toMoment(config.date).add(1, 'year').isLeapYear()

export const isGregorianLeapYear = (config: HDateConfig) =>
  toMoment(config.date).isLeapYear()

export const isHebrewLeapYear = (date: DateValue) =>
  toHebcalDate(date).isLeapYear()

export const isEseresYemeiTeshuva = ({date}: HDateConfig) => {
  const {day, month} = toHebrewMoment(date)
  return month === Months.Tishrei && day < 11
}

export const isIsraelRain = (config: HDateConfig): boolean => {
  const {month, day} = toHebrewMoment(config.date)
  switch (month) {
    // Winter months
    case Months.Kislev:
    case Months.Teves:
    case Months.Shvat:
    case Months.Adar:
    case Months.AdarII:
      return true
    // Summe months
    case Months.Eyar:
    case Months.Sivan:
    case Months.Taamuz:
    case Months.Av:
    case Months.Elul:
    case Months.Tishrei:
      return false
    // Border months
    case Months.Nissan:
      return day < 15
    case Months.Cheshvan:
      return day > 7
  }
  return false
}

export const formatHebrewDate = (config: DateValue): string => {
  const {month, day} = toHebrewMoment(config)
  return `${gematriya(day)} ${getNameForMonth(month, isHebrewLeapYear(config))}`
}

export const isIsraelRainNew = (config: HDateConfig): boolean =>
  isIsraelRain(config) !== isIsraelRain(addDays(config, -30))

export const isChulRainNew = (config: HDateConfig): boolean =>
  isRainChul(config) !== isRainChul(addDays(config, -30))

export const getDayOfWeek = (config: HDateConfig): WeekDays =>
  toMoment(config.date).weekday()

// return 0 if not omer
export const getOmerDay = (config: HDateConfig): number => {
  const hebCalDate = toHebcalDate(config.date)
  const pesachStart = toHebcalDate({
    month: Months.Nissan,
    day: 16,
    year: getHebrewYear(config),
  })
  const daysFromStart = hebCalDate.deltaDays(pesachStart) + 1
  return daysFromStart < 1 || daysFromStart > 50 ? 0 : daysFromStart
}

export const isCandleLight = (config: HDateConfig): boolean => {
  const {day, month} = toHebrewMoment(config.date)
  if (getDayOfWeek(config) === WeekDays.Friday) return true

  if (month === Months.Nissan && day === 14) return true
  if (month === Months.Sivan && day === 5) return true
  if (month === Months.Elul && day === 29) return true
  if (month === Months.Tishrei && day === 9) return true
  if (month === Months.Tishrei && day === 14) return true
  if (isPesach(config) && day === 20) return true
  if (isSuccos(config) && day === 19) return true
  if (isPesach(config) && day === 21 && !config.inIsrael) return true
  if (isSuccos(config) && day === 20 && !config.inIsrael) return true
  return false
}

export const getDayOfChanuka = (config: HDateConfig): number => {
  const {month, day} = toHebrewMoment(config.date)

  if (month === Months.Kislev) {
    switch (day) {
      case 25:
        return 1
      case 26:
        return 2
      case 27:
        return 3
      case 28:
        return 4
      case 29:
        return 5
      case 30:
        return 6
    }
  }
  if (month === Months.Teves) {
    const isKislevLong = !HDate.shortKislev(getHebrewYear(config))
    switch (day) {
      case 1:
        return isKislevLong ? 7 : 6
      case 2:
        return isKislevLong ? 8 : 7
      case 3:
        return 8
    }
  }
  return -1
}

export const isPurimToday = (config: HDateConfig): boolean => {
  const holiday = config.inJerusalem ? Holiday.ShushanPurim : Holiday.Purim
  return getHolidays(config).find((h) => h === holiday) != null
}

export const isYomTov = ({inIsrael, date}: HDateConfig): boolean => {
  const {month, day} = toHebrewMoment(date)

  return (
    (month === Months.Nissan && day === 15) || //pesach
    (month === Months.Nissan && day === 16 && !inIsrael) || // pesach galus
    (month === Months.Nissan && day === 21) || // shvii shel pesach
    (month === Months.Nissan && day === 22 && !inIsrael) || // achron shel
    (month === Months.Sivan && day === 6) || // Shavuos
    (month === Months.Sivan && day === 7 && !inIsrael) || // shavuos galus
    (month === Months.Tishrei && day === 1) || //RoshHashana
    (month === Months.Tishrei && day === 2) || //RoshHashana
    (month === Months.Tishrei && day === 10) || //YomKippur
    (month === Months.Tishrei && day === 15) || //succos
    (month === Months.Tishrei && day === 16 && !inIsrael) || // succos galus
    (month === Months.Tishrei && day === 22) || //Shmini Atzeres
    (month === Months.Tishrei && day === 23 && !inIsrael)
  ) // Simchas Torah
}

export const isErevPesach = ({date}: HDateConfig) => {
  const {month, day} = toHebrewMoment(date)
  return month === Months.Nissan && day === 14
}

export const isPesach = ({date, inIsrael}: HDateConfig): boolean => {
  const {month, day} = toHebrewMoment(date)
  const maxDay = inIsrael ? 21 : 22
  if (month !== Months.Nissan) return false
  if (day < 15 || day > maxDay) return false
  return true
}

export const isRoshHashana = ({date}: HDateConfig): boolean => {
  const {month, day} = toHebrewMoment(date)
  if (month !== Months.Tishrei) return false
  return day === 1 || day === 2
}

export const isSuccos = ({date, inIsrael}: HDateConfig): boolean => {
  const {month, day} = toHebrewMoment(date)
  const maxDay = inIsrael ? 22 : 23
  if (month !== Months.Tishrei) return false
  if (day < 15 || day > maxDay) return false
  return true
}

export const isFastDay = (config: HDateConfig): boolean => {
  const fastDays: Holiday[] = [
    Holiday.TzomGedaliah,
    Holiday.TzomTeives,
    Holiday.TzomTaamuz,
    Holiday.TishaBav,
    Holiday.TaanisEsther,
  ]
  return getHolidays(config).some((h) => fastDays.includes(h))
}

export const isRoshChodesh = ({date}: HDateConfig) => {
  const {day} = toHebrewMoment(date)
  return day === 30 || day === 1
}

export const isMussaf = (config: HDateConfig): boolean => {
  if (isRoshChodesh(config)) return true
  if (isYomTov(config)) return true
  if (isSuccos(config)) return true
  if (isPesach(config)) return true
  if (getDayOfWeek(config) === WeekDays.Shabbos) return true

  return false
}

export const isChanuka = (config: HDateConfig): boolean =>
  getDayOfChanuka(config) > -1
