import {
  SelectChangeEvent,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Select,
  MenuItem,
  Switch,
} from '@mui/material'
import {isBookKey, BookKey} from 'books/book.models'
import {TextRefrenceElement} from 'database/models/textReference.model'
import {useTanach} from 'database/tanach.provider'
import {getTranslatedValue, Language, Translation} from 'models'
import {useEffect, useMemo} from 'react'
import {isNullOrEmpty, Log, oneIndexedArrayOfRange} from 'utils'
import {formatTanachStringForSiddur} from 'utils/string.util'

interface Props {
  textReference: TextRefrenceElement
  onChange: (ref: TextRefrenceElement) => void
}

export const TextReferenceEditor = ({
  textReference,
  onChange: _onChange,
}: Props) => {
  const {
    book,
    chapter,
    startVerse = 1,
    endVerse,
    breakLineAtVerse,
    resolvedText,
  } = textReference
  const {book: bookValue, setBookKey} = useTanach('english')

  useEffect(() => {
    setBookKey(book)
  }, [book])

  const onChange = (ref: TextRefrenceElement) => {
    _onChange({
      ...ref,
      resolvedText: resolveTextRef(bookValue, ref),
    })
  }

  const onSetBook = (event: any, newValue: string | null) => {
    if (newValue && isBookKey(newValue)) {
      onChange({
        ...textReference,
        book: newValue,
        chapter: 1,
        startVerse: 1,
        endVerse: 1,
      })
    }
  }

  const onSetChapter = (event: SelectChangeEvent) => {
    const newValue = event.target.value
    if (newValue && !isNaN(+newValue)) {
      onChange({...textReference, chapter: +newValue})
    }
  }

  type verseKeys = 'startVerse' | 'endVerse'
  const onSetVerse = (verseKey: verseKeys) => (event: SelectChangeEvent) => {
    const newValue = event.target.value
    if (
      newValue &&
      !isNaN(+newValue) &&
      +newValue < bookValue[chapter - 1].length + 1
    ) {
      const copyVal = {...textReference}
      copyVal[verseKey] = +newValue
      console.log(
        Math.max(
          copyVal.startVerse || 1,
          copyVal.endVerse || bookValue[chapter - 1].length,
        ),
      )
      copyVal.endVerse = Math.max(
        copyVal.startVerse || 1,
        copyVal.endVerse || bookValue[chapter - 1].length,
      )
      onChange(copyVal)
    }
  }

  const setBreakAtVerse = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange({
      ...textReference,
      breakLineAtVerse: event.target.checked,
    })
  }

  return (
    <Stack>
      {!isNullOrEmpty(bookValue) && (
        <Typography marginBottom="16px" variant="body1" dir="auto">
          {getTranslatedValue(resolvedText)}
        </Typography>
      )}
      <Autocomplete
        disablePortal
        value={book}
        onChange={onSetBook}
        id="combo-box-demo"
        options={BookKey}
        sx={{width: 300}}
        renderInput={(params) => <TextField {...params} label="Book" />}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={breakLineAtVerse}
              onChange={setBreakAtVerse}
              inputProps={{'aria-label': 'controlled'}}
            />
          }
          label="Break Line At Verse"
        />
      </FormGroup>
      {bookValue[chapter - 1] && (
        <>
          <Typography variant="caption">Chapter</Typography>
          <Select label="Chapter" value={chapter + ''} onChange={onSetChapter}>
            {oneIndexedArrayOfRange(bookValue.length).map((n) => (
              <MenuItem value={n}>{n + ''}</MenuItem>
            ))}
          </Select>
          <Typography variant="caption">Start Verse</Typography>
          <Select
            label="Start Verse"
            value={startVerse + ''}
            onChange={onSetVerse('startVerse')}
          >
            {oneIndexedArrayOfRange(bookValue[chapter - 1].length).map((n) => (
              <MenuItem value={n}>{n + ''}</MenuItem>
            ))}
          </Select>
          <Typography variant="caption">End Verse</Typography>
          <Select
            label="End Verse"
            value={(endVerse || bookValue[chapter - 1].length) + ''}
            onChange={onSetVerse('endVerse')}
          >
            {oneIndexedArrayOfRange(bookValue[chapter - 1].length)
              .filter((n) => n >= startVerse)
              .map((n) => (
                <MenuItem value={n}>{n + ''}</MenuItem>
              ))}
          </Select>
        </>
      )}
    </Stack>
  )
}

const resolveTextRef = (
  bookText: Translation[][],
  {chapter, breakLineAtVerse, endVerse, startVerse}: TextRefrenceElement,
): Translation => {
  const chapterText = bookText[chapter - 1]
  const start = (startVerse ?? 1) - 1
  const end = endVerse ?? chapterText.length
  const range = Array.from({length: end - start}, (v, index) => index + start)
  const terminalWhitespace = breakLineAtVerse ? '\n' : ' '

  if (isNullOrEmpty(bookText[chapter - 1])) return {hebrew: ''}

  return Object.keys(bookText[chapter - 1][0]).reduce((acc, language) => {
    acc[language] = formatTanachStringForSiddur(
      range.reduce(
        (str, pasuk) =>
          str +
          getTranslatedValue(chapterText[pasuk], language as Language) +
          terminalWhitespace,
        '',
      ),
    )
    return acc
  }, {} as Translation)
}
