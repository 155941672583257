export const StylePosition = ['All', 'First', 'Last'] as const
export type StylePosition = typeof StylePosition[number]

export const StyleTypes = ['Bold', 'Large', 'SpecialColor', 'Small'] as const
export type StyleTypes = typeof StyleTypes[number]

export interface TextStyle {
  name: StyleTypes
  position: StylePosition
  words: number
}
