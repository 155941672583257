import {useFirebase} from 'firebase/firebaseRoot'
import {ref, onValue, child, get, update, set} from 'firebase/database'
import {Log} from 'utils'
import {useEffect, useState} from 'react'
import {Category} from 'database/models/category.model'
import {Tefila} from 'database/models/tefila.model'
import {FirebaseDBSchema} from 'database/models/dbSchema.model'
import {getPathForKeyInType, getPathForType} from 'database/dbUtils'
import {Section, TextElement, TextGroup} from 'models'
import {TextRefrenceElement} from 'database/models/textReference.model'

const TAG = 'LiveTextGroupProvider'

export const useLiveProvider = <
  T extends
    | TextGroup
    | Tefila
    | Section
    | TextElement
    | TextRefrenceElement
    | Category,
>(
  type: keyof FirebaseDBSchema,
  key?: string,
) => {
  const {db} = useFirebase()
  const [value, setValue] = useState<T>()

  useEffect(() => {
    if (!key) return
    onValue(ref(db, getPathForKeyInType(type, key)), (snapshot) => {
      if (snapshot.exists()) {
        setValue({...snapshot.val(), key})
      }
    })
  }, [key, db])

  const updateValue = (t: T) => {
    Log.d(TAG, 'updating', t)
    if (!key) {
      Log.w(TAG, 'no key!')
      return
    }
    const newData: Partial<T> = Object.keys(t).reduce((acc, k) => {
      if (t[k] !== undefined) {
        acc[k] = t[k]
      }
      return acc
    }, {} as Partial<T>)
    delete newData['key']
    if (Object.keys(newData).length === 0) setValue(undefined)
    set(ref(db, getPathForKeyInType(type, key)), newData)
  }

  return {value, updateValue}
}
