import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from '@mui/material'
import {useFirebaseDb} from 'database/firebaseProvider'
import {useEditContext} from 'edit/edit.context'
import {getTranslatedValue} from 'models'
import {useSiddurContext} from 'siddurContext/siddurRoot'

const TAG = 'CategoryList'

export const CategoriesList = () => {
  const {categories, tefilos} = useFirebaseDb()
  const {goTo, editEnabled} = useEditContext()
  const {nusach, setTefilaKey, evaluate} = useSiddurContext()

  const setTefila = (tefilaKey: string) => () => {
    if (editEnabled) {
      goTo({type: 'tefila', key: tefilaKey}, true)
    }
    setTefilaKey(tefilaKey)
  }

  const setCategory = (key: string) => () => {
    if (editEnabled) {
      goTo({type: 'category', key}, true)
      return
    }
  }

  const clearAll = () => {
    setTefilaKey(undefined)
  }

  return (
    <List>
      <ListItem button onClick={clearAll}>
        לוח היום
      </ListItem>
      {categories.map((cat) => {
        return (
          <>
            {editEnabled && (
              <ListItem button onClick={setCategory(cat.key)}>
                <ListSubheader>
                  {getTranslatedValue(cat.resolvedName || {hebrew: 'cat'})}
                </ListSubheader>
              </ListItem>
            )}
            {!editEnabled && (
              <ListSubheader>
                {getTranslatedValue(cat.resolvedName || {hebrew: 'cat'})}
              </ListSubheader>
            )}
            <List>
              {cat.tefilaKeys.map((tefilaKey) => {
                const tefila = tefilos.find(({key}) => key === tefilaKey)
                if (!editEnabled && !evaluate(tefila?.evaluation)) {
                  return null
                }
                if (!tefila) return null
                return (
                  <ListItem button onClick={setTefila(tefila.key)}>
                    {getTranslatedValue(
                      tefila.resolvedName || {herbew: 'blank'},
                    )}
                  </ListItem>
                )
              })}
            </List>
          </>
        )
      })}
    </List>
  )
}
