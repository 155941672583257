import {Container, Stack, Typography} from '@mui/material'
import {getNameForParsha} from 'models/parsha.model'
import moment from 'moment'
import {
  formatHebrewDate,
  getHebrewHolidays,
  isCandleLight,
  isRain,
  isRainNew,
  isSummer,
  isSummerNew,
  getParsha,
  getDafYomi,
} from 'siddurCalendar'
import {zmanValues} from 'siddurCalendar/zmanim/zman.calculator'
import {ZmanValue} from 'siddurCalendar/zmanim/zman.model'
import {useSiddurContext} from 'siddurContext/siddurRoot'

export const LuachHayom = () => {
  const {tefilaKey} = useSiddurContext()
  if (!!tefilaKey) return null
  return (
    <Container sx={{display: 'flex', justifyContent: 'center'}}>
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flex: 1,
        }}
      >
        <ZmanimBox />
        <DateBox />
      </Stack>
    </Container>
  )
}

const DateBox = () => {
  const {hebDate} = useSiddurContext()

  return (
    <Stack sx={{flex: 1, marginLeft: '16px'}}>
      <DateItem title={formatHebrewDate(hebDate.date)} />
      {getHebrewHolidays(hebDate).map((h) => (
        <DateItem title={h} />
      ))}
      {isCandleLight(hebDate) && (
        <DateItem
          title="הדלקת נרות"
          value={zmanValues.candle_light(hebDate).format('H:mm')}
        />
      )}
      {isRainNew(hebDate) && isRain(hebDate) && <DateItem title="תן טל ומטר" />}
      {isSummerNew(hebDate) && isSummer(hebDate) && (
        <DateItem title="מוריד הגשם" />
      )}
      {isSummerNew(hebDate) && !isSummer(hebDate) && (
        <DateItem title="מוריד הטל" />
      )}
      <DateItem title="פרשה" value={getNameForParsha(getParsha(hebDate))} />
      <DateItem title="דף" value={getDafYomi(hebDate)} />
    </Stack>
  )
}

interface DateItemProps {
  title: string
  value?: string
}

const DateItem = ({title, value}: DateItemProps) => {
  return (
    <Stack
      sx={{
        marginBottom: '.5rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <Typography>{value}</Typography>
      <Typography>{title}</Typography>
    </Stack>
  )
}

const ZmanimBox = () => {
  const {hebDate} = useSiddurContext()

  return (
    <Stack sx={{flex: 1}}>
      {/* {hebDate.getZemanim().map((z) => (
        <ZmanItem zman={z} />
      ))} */}
    </Stack>
  )
}

const ZmanItem = ({zman}: {zman: ZmanValue}) => {
  const isAfter = moment().isAfter(zman.date)
  const textColor = isAfter ? 'text.secondary' : 'text.primary'
  const fontWeight = isAfter ? undefined : 'bold'
  return (
    <Stack
      sx={{
        marginBottom: '.5rem',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flex: 1,
      }}
    >
      <Typography sx={{color: textColor, fontWeight}}>
        {zman.date.format('H:mm')}
      </Typography>
      <Typography sx={{color: textColor, fontWeight}}>{zman.key}</Typography>
    </Stack>
  )
}
