import {createTranslationSheet, Language} from 'models/translation.model'
import moment from 'moment'

export interface ZmanValue {
  key: Zman
  date: moment.Moment
}

export const Zman = {
  AlosHaShachar: 'alot_hashachaar',
  MiSheyakir: 'misheyakir',
  HaNeitzHaChama: 'neitz_hachama',
  SofZmanShma: 'sof_zman_shma',
  SofZmanTefila: 'sof_zman_tfilla',
  Chatzos: 'chatzot',
  MinchaGedola: 'mincha_gedola',
  MinchaKetana: 'mincha_ketana',
  PlagHaMincha: 'plag_hamincha',
  HadlakasNeiros: 'candle_light',
  Shkiah: 'shkiah',
  SiyumTzom: 'siyum_tzom',
  SiyumTzomRavMoshe: 'siyum_tzom_rav_moshe',
  TzeisEarly: 'tzeis_early',
  Tzeis: 'tzeit',
  TzeisRT: 'tzeis_rt',
  ChatzosHaLaila: 'chatzot_night',
} as const

export type Zman = typeof Zman[keyof typeof Zman]

const zmanimNamesHebrew: Record<Zman, string> = {
  alot_hashachaar: 'עלות השחר',
  misheyakir: 'משיכיר',
  neitz_hachama: 'הנץ החמה',
  sof_zman_shma: 'ס׳׳ז שמע',
  sof_zman_tfilla: 'ס׳׳ז תפילה',
  chatzot: 'חצות',
  mincha_gedola: 'מנחה גדולה',
  mincha_ketana: 'מנחה קטנה',
  plag_hamincha: 'פלג המנחה',
  candle_light: 'הדלקת נרות',
  shkiah: 'שקיעת החמה',
  tzeis_early: 'זמן מוקדם לק׳׳ש',
  tzeit: 'צאת הככבים',
  tzeis_rt: 'צאת ר׳׳ת',
  siyum_tzom: 'סיום הצום',
  siyum_tzom_rav_moshe: 'סיום הצום (רב משה)',
  chatzot_night: 'חצות הלילה',
}

const zmanimNamesEnglish: Record<Zman, string> = {
  alot_hashachaar: 'Dawn',
  misheyakir: 'Earliest Talis/Tefilin',
  neitz_hachama: 'Sunrise',
  sof_zman_shma: 'Latest Shma',
  sof_zman_tfilla: 'Latest Morning Prayers',
  chatzot: 'Midday',
  mincha_gedola: 'Earliest Afternoon Prayers',
  mincha_ketana: 'Mincha Ketana',
  plag_hamincha: 'Midafternoon',
  candle_light: 'Candle Lighting',
  shkiah: 'Sunset',
  siyum_tzom: 'Fast Ends',
  siyum_tzom_rav_moshe: 'Fast Ends (Rav Moshe)',
  tzeis_early: 'Earliest Time Shma',
  tzeit: 'Nightfall',
  tzeis_rt: 'Nightfall Rebbeinu Tam',
  chatzot_night: 'Midnight',
}

export const zmanimNames = createTranslationSheet({
  hebrew: zmanimNamesHebrew,
  english: zmanimNamesEnglish,
})

export const getLabelForZman = (zman: Zman, language: Language) =>
  zmanimNames[zman][language]
