import {
  Select,
  MenuItem,
  Stack,
  IconButton,
  SelectChangeEvent,
  Divider,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import {isNullOrEmpty, oneIndexedArrayOfRange} from 'utils'
import {EditorSubHeader} from 'edit/editorHeader.widget'
interface Props {
  omerDays?: number[]
  onChange: (days: number[]) => void
}

const days = oneIndexedArrayOfRange(49)
export const OmerEdit = ({omerDays = [], onChange}: Props) => {
  const onDayPicked = (index: number) => (event: SelectChangeEvent<number>) => {
    const copiedDays = [...omerDays]
    copiedDays[index] = +event.target.value
    onChange(copiedDays)
  }

  const deleteDay = (index: number) => () => {
    const newList = [...omerDays]
    newList.splice(index, 1)
    onChange(newList)
  }
  if (isNullOrEmpty(omerDays)) return null
  return (
    <Stack>
      <EditorSubHeader name="Omer" />
      {omerDays.map((p, index) => (
        <Stack direction="row">
          <Select
            labelId="field-select-label"
            id="field-select"
            label="Field"
            value={p}
            onChange={onDayPicked(index)}
          >
            {days.map((day) => (
              <MenuItem value={day}>{day}</MenuItem>
            ))}
          </Select>
          <IconButton onClick={deleteDay(index)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  )
}
