import {Stack, TextField} from '@mui/material'
import {useLiveString} from 'database/live/liveStringProvider'
import {EditorSubHeader} from 'edit/editorHeader.widget'
import {Language} from 'models'
interface Props {
  name?: string
  onNameKeyChange: (key: string) => void
}

export const NameEditor = ({name, onNameKeyChange}: Props) => {
  const {hebrew, english, update} = useLiveString(name)

  const onNameValueChange =
    (language: Language) => (event: React.ChangeEvent<HTMLInputElement>) => {
      update(language, event.target.value)
    }

  const onKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onNameKeyChange(event.target.value)
  }

  return (
    <Stack spacing="16px">
      <EditorSubHeader name="Name" />
      <TextField
        id="outlined-name"
        variant="standard"
        label="Name Key"
        value={name}
        onChange={onKeyChange}
      />
      <Stack direction="row" spacing="8px">
        <TextField
          inputProps={{dir: 'auto'}}
          id="outlined-hebrew"
          variant="outlined"
          label="Hebrew"
          value={hebrew}
          onChange={onNameValueChange('hebrew')}
        />
        <TextField
          id="outlined-english"
          variant="outlined"
          label="English"
          value={english}
          onChange={onNameValueChange('english')}
        />
      </Stack>
    </Stack>
  )
}
