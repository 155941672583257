import {Box, Container} from '@mui/material'
import {useLiveProvider} from 'database/live/liveProvider'
import {useEditContext} from 'edit/edit.context'
import {EditorHeader} from 'edit/editorHeader.widget'
import {EvaluationEditor} from 'edit/evaluation/evaluationEditor.component'
import {NusachMapEditor} from 'edit/nusachMapEditor.widget'
import {NusachMap, Evaluation, TextGroup} from 'models'
import {Log} from 'utils'

const TAG = 'TextGroupEditor'

interface Props {
  textGroupKey: string
}
export const TextGroupEditor = ({textGroupKey}: Props) => {
  const {value = {key: textGroupKey, elements: {ashkenaz: []}}, updateValue} =
    useLiveProvider<TextGroup>('text-groups', textGroupKey)
  const {goTo} = useEditContext()

  const onElementsReordered = (newMap: NusachMap<string[]>) => {
    updateValue({...value!, elements: newMap})
  }

  const onUpdateEvaluation = (evaluation?: Evaluation) => {
    updateValue({...value, evaluation})
  }

  const onTextElementClicked = (textElementKey: string) => {
    goTo({type: 'textelement', key: textElementKey})
  }

  return (
    <Container>
      <EditorHeader name={'TextGroup: ' + textGroupKey} />

      <NusachMapEditor
        title="Text Elements"
        nusachMap={value.elements || {ashkenaz: []}}
        onValueChange={onElementsReordered}
        onItemClick={onTextElementClicked}
      />
      <EvaluationEditor
        evaluation={value.evaluation}
        onUpdate={onUpdateEvaluation}
      />
    </Container>
  )
}
