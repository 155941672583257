export interface Translation {
  hebrew: string
  english?: string
  transliterated?: string
}

export type Language = keyof Translation

export const getTranslatedValue = (
  translation?: Translation,
  language: Language = 'hebrew',
): string => (translation ? translation[language] || translation.hebrew : '')

export type TranslationSheet<T extends PropertyKey & string> = Record<
  T,
  Translation
>

export const createTranslationSheet = <
  T extends Record<string, string>,
  P extends keyof T & string,
>({
  hebrew,
  english = {},
}: {
  hebrew: T
  english?: Partial<T>
}) => {
  return Object.keys(hebrew).reduce((acc, key) => {
    acc[key] = {hebrew: hebrew[key], english: english[key]}
    return acc
  }, {} as TranslationSheet<P>)
}

export const translateSheet = <T extends TranslationSheet<any>>(
  sheet: T,
  language: Language,
) =>
  Object.keys(sheet).reduce((acc, key) => {
    acc[key as keyof T] = getTranslatedValue(sheet[key], language)
    return acc
  }, {} as Record<keyof T, string>)
