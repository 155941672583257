import * as React from 'react'
import AppBarBase from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import {useAuth} from 'auth/auth.hook'
import {Avatar, FormControlLabel, FormGroup, Switch} from '@mui/material'
import {useEditContext} from 'edit/edit.context'
import {useScreenSize} from 'theme/smallScreen.hook'

const adminId = 'TwIdrx1lTlV5NB2ciSU4BJo08LW2'

interface Props {
  onMenuIconClick?: () => void
}

export const AppBar = ({onMenuIconClick}: Props) => {
  const {user, signInWithGoogle, signOut} = useAuth()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const {editEnabled, setEditEnabeld} = useEditContext()
  const {isMobile} = useScreenSize()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (user) signOut()
    if (!user) signInWithGoogle()
  }

  const handleToggleEditMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditEnabeld(event.target.checked)
  }

  const isAdmin = user && user.id === adminId

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBarBase position={'sticky'}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onMenuIconClick}
            sx={{mr: 2}}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            Siddur One
          </Typography>

          <div>
            {isAdmin && !isMobile && (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={editEnabled}
                      onChange={handleToggleEditMode}
                      aria-label="login switch"
                    />
                  }
                  label={'Admin Mode'}
                />
              </FormGroup>
            )}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              {user && <Avatar src={user.avatarUrl} />}
              {!user && <AccountCircle />}
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
                {user ? 'Log out' : 'Sign In'}
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBarBase>
    </Box>
  )
}
