import {
  Select,
  MenuItem,
  Stack,
  IconButton,
  SelectChangeEvent,
  Divider,
  Typography,
} from '@mui/material'
import {Parsha} from 'models/parsha.model'
import DeleteIcon from '@mui/icons-material/Delete'
import {isNullOrEmpty} from 'utils'
import {EditorSubHeader} from 'edit/editorHeader.widget'
interface Props {
  parshios?: Parsha[]
  onChange: (parshios?: Parsha[]) => void
}

export const ParshaEdit = ({parshios = [], onChange}: Props) => {
  const onParshaPicked =
    (index: number) => (event: SelectChangeEvent<Parsha>) => {
      const copiedParshios = {...parshios}
      copiedParshios[index] = event.target.value as Parsha
      onChange(copiedParshios)
    }

  const deleteParsha = (index: number) => () => {
    const newList = [...parshios]
    newList.splice(index, 1)
    onChange(newList)
  }
  if (isNullOrEmpty(parshios)) return null
  return (
    <Stack>
      <EditorSubHeader name="Parsha" />
      {parshios.map((p, index) => (
        <Stack direction="row">
          <Select
            labelId="field-select-label"
            id="field-select"
            label="Field"
            value={p}
            onChange={onParshaPicked(index)}
          >
            {Parsha.map((parsha) => (
              <MenuItem value={parsha}>{parsha}</MenuItem>
            ))}
          </Select>
          <IconButton onClick={deleteParsha(index)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}
    </Stack>
  )
}
