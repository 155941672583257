import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  IconButton,
  TextField,
  Autocomplete,
} from '@mui/material'
import {OperatorBox} from 'edit/evaluation/operatorEdit.widget'
import {
  EvaluationElement,
  EvaluationField,
  isEvaluationField,
  Operators,
} from 'models'
import DeleteIcon from '@mui/icons-material/Delete'

interface Props {
  element: EvaluationElement
  onChange: (element: EvaluationElement) => void
  deleteElement: () => void
}

export const EvaluationElementEditor = ({
  element,
  onChange,
  deleteElement,
}: Props) => {
  const onOperatorChange = (operator: Operators) => {
    onChange({...element, operator})
  }

  const onChangeCheck =
    (field: EvaluationField) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const updated = {...element}
      updated[field] = event.target.checked
      onChange(updated)
    }

  const handleFieldSelected = (event: any, newValue: string | null) => {
    if (newValue && isEvaluationField(newValue)) {
      const copiedElement = {...element}
      copiedElement[newValue] = true
      onChange(copiedElement)
    }
  }

  const elementFields = Object.keys(element).filter(isEvaluationField)

  return (
    <Stack direction="row">
      <OperatorBox onChange={onOperatorChange} operator={element.operator} />
      {elementFields.map((field) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={element[field]}
              onChange={onChangeCheck(field)}
            />
          }
          label={field}
        />
      ))}
      <Autocomplete
        disablePortal
        onChange={handleFieldSelected}
        id="combo-box-demo"
        options={EvaluationField}
        sx={{width: 300}}
        renderInput={(params) => <TextField {...params} label="Field" />}
      />
      <IconButton onClick={deleteElement}>
        <DeleteIcon />
      </IconButton>
    </Stack>
  )
}
