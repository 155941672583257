import {
  Container,
  IconButton,
  List,
  ListItem,
  Stack,
  TextField,
} from '@mui/material'
import {useLiveProvider} from 'database/live/liveProvider'
import {EditorHeader} from 'edit/editorHeader.widget'
import {NameEditor} from 'edit/nameEditor.widget'
import {Category} from 'models'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/AddCircle'
import {useEditContext} from 'edit/edit.context'
import {useState} from 'react'

interface Props {
  categoryKey: string
}

const reorder = <T extends any>(
  list: Array<T>,
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const CategoryEdit = ({categoryKey}: Props) => {
  const {
    value = {
      tefilaKeys: [],
      name: '',
      order: 0,
      key: categoryKey,
      resolvedName: {hebrew: ''},
    },
    updateValue,
  } = useLiveProvider<Category>('categories', categoryKey)
  const {goTo} = useEditContext()
  const [newKey, setNewKey] = useState<string>()

  const onNameKeyChange = (nameKey: string) => {
    updateValue({...value, name: nameKey})
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const items = value.tefilaKeys
    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    )
    updateValue({...value, tefilaKeys: reordered})
  }

  const onDeleteItem = (index: number) => () => {
    const newList = [...value.tefilaKeys]
    newList.splice(index, 1)
    updateValue({...value, tefilaKeys: newList})
  }

  const onTefilaClicked = (key: string) => () => {
    goTo({type: 'tefila', key})
  }

  const addKey = () => {
    if (!newKey) return
    updateValue({...value, tefilaKeys: [...value.tefilaKeys, newKey]})
  }

  return (
    <Container>
      <EditorHeader name={'Category: ' + categoryKey} />
      <NameEditor name={value.name} onNameKeyChange={onNameKeyChange} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {value.tefilaKeys.map((item, index) => (
                <Draggable
                  key={item + index}
                  draggableId={item + index}
                  index={index}
                >
                  {(innerProvided, innerSnapshot) => (
                    <div
                      ref={innerProvided.innerRef}
                      {...innerProvided.draggableProps}
                      {...innerProvided.dragHandleProps}
                    >
                      <Stack direction="row">
                        <ListItem button onClick={onTefilaClicked(item)}>
                          {item}
                        </ListItem>
                        <IconButton
                          aria-label="delete"
                          onClick={onDeleteItem(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
      <>
        <TextField
          id="standard-basic"
          label="Add Key"
          variant="standard"
          value={newKey}
          onChange={(e) => setNewKey(e.target.value)}
        />
        <IconButton aria-label="Add" onClick={addKey}>
          <AddIcon />
        </IconButton>
      </>
    </Container>
  )
}
