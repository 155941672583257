import {typeguardGenerator} from 'utils'

export const EvaluationField = [
  'Tachanun',
  'TachanunDate',
  'MinchaTachanunDate',
  'MinchaTachanun',
  'MiddleTachanun',
  'LongTachanun',
  'Lamnatzeach',
  'KirasHaTorah',
  'Mincha',
  'Maariv',
  'Shacharis',
  'Hallel',
  'FullHallel',
  'Mussaf',
  'CandleLighting',
  'FastDay',
  'IsSummer',
  'IsSummerNew',
  'IsRain',
  'IsRainNew',
  'IsShabbos',
  'IsYomTov',
  'Motzash',
  'VayitenLecha',
  'Havdalah',
  'BirkasLevana',
  'RoshChodesh',
  'YaaleVYavo',
  'HakamasHaMishkan',
  'HakamasHaMishkan1',
  'HakamasHaMishkan2',
  'HakamasHaMishkan3',
  'HakamasHaMishkan4',
  'HakamasHaMishkan5',
  'HakamasHaMishkan6',
  'HakamasHaMishkan7',
  'HakamasHaMishkan8',
  'HakamasHaMishkan9',
  'HakamasHaMishkan10',
  'HakamasHaMishkan11',
  'HakamasHaMishkan12',
  'LeilBedika',
  'ErevPesach',
  'Pesach1',
  'Pesach2',
  'Pesach3',
  'Pesach4',
  'Pesach5',
  'Pesach6',
  'Pesach7',
  'Pesach8',
  'Pesach',
  'Omer',
  'YomHaShoah',
  'YomHaZikaron',
  'YomHaAtzmaut',
  'LagBomer',
  'YomYerushaleim',
  'EiruvTavshilin',
  'Shavuos1',
  'Shavuos2',
  'Shavuos',
  'TzomTaamuz',
  'NineDays',
  'ErevTishaBAv',
  'TishaBAv',
  'TuBAv',
  'Ldovid',
  'ErevRoshHaShana',
  'RoshHashana',
  'RoshHashana1',
  'RoshHashana2',
  'Tashlich',
  'EseresYemeiTeshuva',
  'TzomGedalia',
  'ErevYomKippur',
  'YomKippur',
  'Succos1',
  'Succos2',
  'Succos3',
  'Succos4',
  'Succos5',
  'Succos6',
  'Succos',
  'HoshanaRaba',
  'SimchasTorah',
  'SheminiAtzeres',
  'ErevChanuka',
  'Chanuka',
  'Chanuka1',
  'Chanuka2',
  'Chanuka3',
  'Chanuka4',
  'Chanuka5',
  'Chanuka6',
  'Chanuka7',
  'Chanuka8',
  'TzomTevs',
  'TaanisEsther',
  'PurimKatan',
  'Purim',
  'Ashkenaz',
  'Sfard',
  'Mizrach',
  'InIsrael',
  'InJerusalem',
  'LaterAdditions',
  'SfardAdditions',
  'OriginalMinim',
  'BirkosHaShacharPolin',
  'Wedding',
  'Bris',
  'Avel',
  'RunningLate',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'HebrewLeapYear',
] as const
export type EvaluationField = typeof EvaluationField[number]
export function isEvaluationField(value: unknown): value is EvaluationField {
  return typeguardGenerator(EvaluationField)(value)
}
export const TempFields = ['Bris', 'Avel', 'Wedding', 'RunningLate'] as const
export type TempFields = typeof TempFields[number]

export type TempFieldState = Record<TempFields, boolean>
