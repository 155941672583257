import {CategoryEdit} from 'edit/categoryEditor.component'
import {useEditContext} from 'edit/edit.context'
import {SectionEditor} from 'edit/sectionEditor.widget'
import {TefilaEdit} from 'edit/tefilaEdit.component'
import {TextElementEditor} from 'edit/textElementEditor'
import {TextGroupEditor} from 'edit/textGroupEditor.component'
import {Log} from 'utils'
import {getLast} from 'utils/array.utils'

const TAG = 'EditPresnter'

export const EditPresenter = () => {
  const {breadcrumbs} = useEditContext()
  const lastItem = getLast(breadcrumbs)
  if (!lastItem) return null
  const {key, type} = lastItem
  switch (type) {
    case 'category':
      return <CategoryEdit categoryKey={key} />
    case 'tefila':
      return <TefilaEdit tefilaKey={key} />
    case 'section':
      return <SectionEditor sectionKey={key} />
    case 'textgroup':
      return <TextGroupEditor textGroupKey={key} />
    case 'textelement':
      return <TextElementEditor textElementKey={key} />
  }
  return null
}
