import {typeguardGenerator} from '../utils'

export const Nusach = ['ashkenaz', 'sfard', 'mizrach'] as const
export type Nusach = typeof Nusach[number]
export function isNusach(value: unknown): value is Nusach {
  return typeguardGenerator(Nusach)(value)
}

export type NusachMap<T> = {
  ashkenaz: T
  sfard?: T | Nusach
  mizrach?: T | Nusach
}

const labels: NusachMap<string> = {
  ashkenaz: 'אשכנז',
  sfard: 'ספרד',
  mizrach: 'מזרח',
}

export const getLabelForNusach = (nusach: Nusach) => labels[nusach]

export const getValueForNusach = <T>(
  nusach: Nusach,
  nusachMap?: NusachMap<T>,
): T | undefined => {
  if (!nusachMap) return undefined
  const nusachValue = nusachMap[nusach]
  if (!nusachValue) return undefined
  if (isNusach(nusachValue)) return getValueForNusach(nusachValue, nusachMap)
  return nusachValue
}

export const getCertainValueForNusach = <T>(
  defaultValue: T,
  nusach: Nusach,
  nusachMap?: NusachMap<T>,
): T => {
  const val = getValueForNusach(nusach, nusachMap)
  return val ?? defaultValue
}
