import {CssBaseline} from '@mui/material'
import {ThemeProvider} from '@mui/system'
import {createTheme} from '@mui/material/styles'
import React from 'react'
import Frank from '../fonts/frank.ttf'

interface Props {
  children: React.ReactNode
}
export const ThemeRoot = ({children}: Props) => {
  const theme = createTheme({
    typography: {
      subtitle2: {
        fontWeight: 'bold',
        color: 'primary',
        paddingTop: '36px',
        fontSize: 16,
      },
      body2: {
        fontFamily: 'Frank',
        lineHeight: 2,
        fontSize: 20,
        whiteSpace: 'pre-line',
      },
    },
    components: {
      MuiTypography: {
        defaultProps: {
          dir: 'auto',
          variantMapping: {},
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
                @font-face {
                    font-family: 'Frank';
                    font-style: normal;
                    font-display: swap;
                    font-weight: 400;
                    src: local('Frank'), local('Frank-Regular'), url(${Frank}) format('ttf');

                }
                `,
      },
    },
  })

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  )
}
