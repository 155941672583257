import {
  evaluate as rootEvaluate,
  Evaluation,
  EvaluatorConfig,
  Language,
  Nusach,
} from 'models'
import React, {useContext, useEffect, useState} from 'react'
import {useLocation} from 'location/location.hook'
import {Location} from 'location/location.model'
import {HDateConfig} from 'siddurCalendar/models/dateConfig.model'

const defaultLocation = {latitude: 31.768318, longitude: 35.213711}
interface Props {
  children: React.ReactNode
}

interface SiddurContextValue {
  nusach: Nusach
  language: Language
  tefilaKey?: string
  hebDate: HDateConfig
  inIsrael: boolean
  inJerusalem: boolean
  laterAdditions: boolean
  sfardAdditions: boolean
  location?: Location
  setNusach: (nusach: Nusach) => void
  setTefilaKey: (key?: string) => void
  setDate: (date: Date) => void
  setCandleLight: (candleLight: number) => void
  setInIsrael: (inIsrael: boolean) => void
  setInJerusalem: (inJlem: boolean) => void
  setLaterAdditions: (enabled: boolean) => void
  setSfardAdditions: (enabled: boolean) => void
}

const emptyFun = () => {}
const defaultContextValue: SiddurContextValue = {
  nusach: 'ashkenaz',
  language: 'hebrew',
  hebDate: {
    candleLightOffset: 18,
    date: {month: 1, day: 1},
    inIsrael: true,
    inJerusalem: true,
    location: defaultLocation,
  },
  inIsrael: true,
  inJerusalem: true,
  laterAdditions: true,
  sfardAdditions: true,
  setNusach: emptyFun,
  setTefilaKey: emptyFun,
  setDate: emptyFun,
  setCandleLight: emptyFun,
  setInIsrael: emptyFun,
  setInJerusalem: emptyFun,
  setLaterAdditions: emptyFun,
  setSfardAdditions: emptyFun,
}

const SiddurContext =
  React.createContext<SiddurContextValue>(defaultContextValue)

export const SiddurRoot = ({children}: Props) => {
  const {location} = useLocation()
  const [nusach, setNusach] = useState<Nusach>('ashkenaz')
  const [language, setLanguage] = useState<Language>('hebrew')
  const [date, setDate] = useState(new Date())
  const [inIsrael, setInIsrael] = useState(true)
  const [inJerusalem, setInJerusalem] = useState(true)
  const [candleLight, setCandleLight] = useState(18)
  const [laterAdditions, setLaterAdditions] = useState(true)
  const [sfardAdditions, setSfardAdditions] = useState(true)
  const [hebDate, setHebDate] = useState<HDateConfig>({
    date,
    candleLightOffset: candleLight,
    inIsrael,
    inJerusalem,
    location: defaultLocation,
  })
  const [tefilaKey, setTefilaKey] = useState<string>()

  useEffect(() => {
    setHebDate({
      date,
      candleLightOffset: candleLight,
      inIsrael,
      inJerusalem,
      location:
        location?.latitude && location.longitude ? location : defaultLocation,
    })
  }, [inIsrael, inJerusalem, date, candleLight, location])

  return (
    <SiddurContext.Provider
      value={{
        nusach,
        language,
        hebDate,
        inIsrael,
        inJerusalem,
        tefilaKey,
        location,
        laterAdditions,
        sfardAdditions,
        setLaterAdditions,
        setSfardAdditions,
        setTefilaKey,
        setNusach,
        setInIsrael,
        setInJerusalem,
        setCandleLight,
        setDate,
      }}
    >
      {children}
    </SiddurContext.Provider>
  )
}

export const useSiddurContext = () => {
  const contextValue = useContext(SiddurContext)

  const config: EvaluatorConfig = {
    hebrewDate: contextValue.hebDate,
    nusach: contextValue.nusach,
    tefila: contextValue.tefilaKey,
  }

  const evaluate = (evaluation?: Evaluation) => rootEvaluate(config, evaluation)

  return {...contextValue, evaluate}
}
