export interface ThemeColors {
  primaryColor: string
  secondaryColor: string
  specialTextColor: string
  primaryTextColor: string
  secondaryTextColor: string
  backgroundColor: string
  backgroundColorDirty: string
  tabOffColor: string
  scrimColor: string
  headerTextColor: string
  warningColor: string
}

export enum ThemeStyle {
  System = 'system',
  Dark = 'dark',
  Light = 'light',
}

export const isThemeDark = (theme: ThemeStyle) => theme === ThemeStyle.Dark
