import {ParshaAlios} from 'books/parsha/aliah.model'
import {FirebaseDBSchema} from 'database/models/dbSchema.model'
import {
  isTextReference,
  TextRefrenceElement,
} from 'database/models/textReference.model'
import {
  Category,
  Tefila,
  Section,
  TextGroup,
  TextElement,
  Translation,
  Language,
} from 'models'
import {formatTanachStringForSiddur} from 'utils/string.util'
import rawdb from './db.json'

const db = rawdb as unknown as FirebaseDBSchema

const BasePath = 'public/'

export const getTranslatedValue = (
  translation: Translation,
  language: Language = 'hebrew',
): string => translation[language] || translation.hebrew

export const getPathForType = (type: keyof FirebaseDBSchema) => BasePath + type
export const getPathForKeyInType = (
  type: keyof FirebaseDBSchema,
  key: string,
) => getPathForType(type) + '/' + key
export const getPathForTranslation = (type: Language, key: string) =>
  'public/' + type + '/' + key

interface ResolvedSchema {
  categories: Category[]
  tefilot: Tefila[]
  sections: Section[]
  textGroups: TextGroup[]
  textElements: TextElement[]
  strings: {
    hebrew: Record<string, string>
    english: Record<string, string>
  }
  tanach: Record<'hebrew', Record<string, Array<Array<string>>>>
  targum: Record<'hebrew', Record<string, Array<Array<string>>>>
  pirkeiAvos: Record<'hebrew', string[][]>
  parshios: ParshaAlios
}
export const noNameSection = 'NoName'

export type Keyless<T> = Omit<T, 'key'>

const resolveHebrewText = (_db: FirebaseDBSchema, text: string) =>
  _db.hebrew[text]
const resolveEnglishText = (_db: FirebaseDBSchema, text: string) =>
  _db.english[text]

export const resolveDb = (): ResolvedSchema => {
  const resolvedCategories = Object.keys(db.categories).reduce((acc, key) => {
    const {name, order, tefilaKeys} = db.categories[key]
    const category: Category = {
      key,
      resolvedName: {
        hebrew: resolveHebrewText(db, name),
        english: resolveEnglishText(db, name),
      },
      name,
      order,
      tefilaKeys,
    }
    return [...acc, category]
  }, [] as Category[])

  const resolvedTefilot = Object.keys(db.tefilot).reduce((acc, key) => {
    const {name, sectionIds, evaluation} = db.tefilot[key]
    const tefila: Tefila = {
      key,
      name,
      resolvedName: {
        hebrew: resolveHebrewText(db, name),
        english: resolveEnglishText(db, name),
      },
      sectionIds,
      evaluation,
    }
    return [...acc, tefila]
  }, [] as Tefila[])

  const resolvedSections = Object.keys(db.sections).reduce((acc, key) => {
    const {name, textGroupIds, evaluation} = db.sections[key]
    const resolvedName = name ? resolveHebrewText(db, name) : noNameSection
    const section: Section = {
      key,
      name,
      resolvedName:
        resolvedName === noNameSection
          ? undefined
          : {
              hebrew: resolvedName,
              english: resolveEnglishText(db, name!),
            },
      textGroupIds,
      evaluation,
    }
    return [...acc, section]
  }, [] as Section[])

  const resolvedTextGroups = Object.keys(db['text-groups']).reduce(
    (acc, key) => {
      const {evaluation, elements} = db['text-groups'][key]
      const textGroup: TextGroup = {
        key,
        elements,
        evaluation,
      }
      return [...acc, textGroup]
    },
    [] as TextGroup[],
  )

  const resolvedTextElements = Object.keys(db['text-elements']).reduce(
    (acc, key) => {
      const dbElement = db['text-elements'][key]
      const {evaluation, styles} = dbElement
      if (isTextReference(dbElement)) {
        const element: TextElement = {
          key,
          resolvedText: {
            hebrew: resolveTextRef(dbElement, 'hebrew'),
            english: resolveTextRef(dbElement, 'english'),
          },
          evaluation,
          styles,
        }

        return [...acc, element]
      } else {
        const textElement: TextElement = {
          key,
          evaluation,
          special: dbElement.special,
          styles,
          text: dbElement.text,
          resolvedText: dbElement.text
            ? {
                hebrew: resolveHebrewText(db, dbElement.text),
                english: resolveEnglishText(db, dbElement.text),
              }
            : {hebrew: ''},
        }
        return [...acc, textElement]
      }
      return acc
    },
    [] as TextElement[],
  )

  const resolved = {
    categories: resolvedCategories,
    tefilot: resolvedTefilot,
    sections: resolvedSections,
    textGroups: resolvedTextGroups,
    textElements: resolvedTextElements,
    strings: {
      hebrew: db.hebrew,
      english: db.english,
    },
    tanach: db.tanach,
    pirkeiAvos: db.pirkeiAvos,
    parshios: db.parshios,
    targum: db.targum,
  }

  return resolved
}

const deKey = <T extends {key: string}>(
  values: T[],
): Record<string, Keyless<T>> => {
  return values.reduce((acc, e) => {
    const opie: Keyless<T> = {...e, key: [], lastUpdated: []}

    acc[e.key] = opie
    return acc
  }, {} as Record<string, Keyless<T>>)
}

export const resolvedBackToFb = (): Pick<
  FirebaseDBSchema,
  'categories' | 'tefilot' | 'text-elements' | 'text-groups' | 'sections'
> => {
  const resolved = resolveDb()
  return {
    ...db,
    'text-elements': resolved.textElements.reduce((acc, element) => {
      const resolvedDbVal = {
        ...db['text-elements'][element.key],
        ...element,
        key: [],
        lastUpdated: [],
      }
      acc[element.key] = resolvedDbVal
      return acc
    }, {} as Record<string, Keyless<TextElement> | Keyless<TextRefrenceElement>>),
    'text-groups': deKey(resolved.textGroups),
    sections: deKey(resolved.sections),
    tefilot: deKey(resolved.tefilot),
    categories: deKey(resolved.categories),
  }
}

const resolveTextRef = (
  textReference: TextRefrenceElement,
  language: keyof FirebaseDBSchema['tanach'],
): string => {
  const {chapter, book, breakLineAtVerse, endVerse, startVerse} = textReference
  const chapterText = db.tanach[language][book][chapter - 1]
  const start = (startVerse ?? 1) - 1
  const end = endVerse ?? chapterText.length
  const range = Array.from({length: end - start}, (v, index) => index + start)
  const terminalWhitespace = breakLineAtVerse ? '\n' : ' '
  return formatTanachStringForSiddur(
    range.reduce(
      (str, pasuk) => str + chapterText[pasuk] + terminalWhitespace,
      '',
    ),
  )
}
