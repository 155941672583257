import {useEffect, useState} from 'react'
import {Log} from 'utils'
import {Location} from './location.model'

const TAG = 'LocationHook'

export const useLocation = () => {
  const [location, setLocation] = useState<Location>()

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }),
      (err) => Log.w(TAG, err),
    )
  }

  useEffect(() => {
    getLocation()
  }, [])

  return {location, getLocation}
}
