export function filterNonNull<T>(
  arr?: Array<T | undefined> | ReadonlyArray<T | undefined>,
): T[] {
  return arr ? (arr.filter((e) => !!e) as T[]) : []
}

export const isNullOrEmpty = (arr?: unknown[] | ReadonlyArray<unknown>) =>
  (arr?.length ?? 0) < 1

export const anyContain = <T extends string>(
  searchTerm: T,
  paramsQueried: Array<T | undefined>,
) =>
  filterNonNull(paramsQueried).some((q) => q.toLowerCase().includes(searchTerm))

export const extractSingleItem = (arr?: any[]) => {
  if (isNullOrEmpty(arr)) return undefined
  if (arr!.length === 1) return arr![0]
  return arr
}

export const removeLastItem = <T>(arr?: T[]) => {
  if (!arr) return undefined
  return arr.slice(0, arr.length - 1)
}

export const getLast = <T>(arr: T[]) => arr[arr.length - 1]

export const oneIndexedArrayOfRange = (lenght: number) =>
  Array.from({length: lenght}, (x, i) => i + 1)

export const arrayOfRange = (start: number, end: number) =>
  Array.from({length: end - start}, (x, i) => i + start)
