import {
  Button,
  Container,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Switch,
  Autocomplete,
  FormControlLabel,
  FormGroup,
  Box,
} from '@mui/material'
import {useLiveProvider} from 'database/live/liveProvider'
import {
  isTextReference,
  TextRefrenceElement,
} from 'database/models/textReference.model'
import {EditorHeader, EditorSubHeader} from 'edit/editorHeader.widget'
import {EvaluationEditor} from 'edit/evaluation/evaluationEditor.component'
import {
  Evaluation,
  Language,
  StylePosition,
  StyleTypes,
  TextElement,
  TextStyle,
  Translation,
} from 'models'
import DeleteIcon from '@mui/icons-material/Delete'
import {isNullOrEmpty, Log, oneIndexedArrayOfRange} from 'utils'
import {useLiveString} from 'database/live/liveStringProvider'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {BookKey, isBookKey} from 'books/book.models'
import {useTanach} from 'database/tanach.provider'
import {useEffect} from 'react'
import {formatTanachStringForSiddur} from 'utils/string.util'
import {TextReferenceEditor} from 'edit/textReferenceEditor'

const TAG = 'TextElementEditor'

interface Props {
  textElementKey: string
}

export const TextElementEditor = ({textElementKey}: Props) => {
  const {value = {text: undefined} as TextElement, updateValue} =
    useLiveProvider<TextElement | TextRefrenceElement>(
      'text-elements',
      textElementKey,
    )

  const onUpdateTextReference = (textReference: TextRefrenceElement) => {
    updateValue(textReference)
  }

  const onUpdateEvaluation = (evaluation?: Evaluation) => {
    updateValue({...value, evaluation})
  }

  const onStylesUpdated = (styles: TextStyle[]) => {
    updateValue({...value, styles})
  }

  const onTextKeyChange = (textKey: string) => {
    updateValue({...value, text: textKey})
  }

  const makeTextRef = () => {
    updateValue({
      book: 'breishis',
      chapter: 1,
      startVerse: 1,
      endVerse: 1,
      breakLineAtVerse: false,
      styles: value.styles,
      evaluation: value.evaluation,
      resolvedText: {hebrew: ''},
      key: textElementKey,
    })
  }

  const makeTextElement = () => {
    updateValue({
      text: undefined,
      key: textElementKey,
      styles: value.styles,
      evaluation: value.evaluation,
      resolvedText: {hebrew: ''},
    })
  }

  const setBreakAtVerse = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isTextReference(value))
      updateValue({...value, breakLineAtVerse: event.target.checked})
  }

  return (
    <Container>
      <EditorHeader name={'Text Element: ' + textElementKey} />
      {isTextReference(value) && (
        <Button variant="text" onClick={makeTextElement}>
          Make Text Element
        </Button>
      )}
      {isTextReference(value) && (
        <TextReferenceEditor
          textReference={value}
          onChange={onUpdateTextReference}
        />
      )}
      {!isTextReference(value) && (
        <Button variant="text" onClick={makeTextRef}>
          Make Reference
        </Button>
      )}
      {!isTextReference(value) && (
        <TextEditor textKey={value.text} onTextKeyChange={onTextKeyChange} />
      )}
      <EvaluationEditor
        evaluation={value.evaluation}
        onUpdate={onUpdateEvaluation}
      />
      <StyleEditor styles={value.styles} onChange={onStylesUpdated} />
    </Container>
  )
}

interface TextEditorProps {
  textKey?: string
  onTextKeyChange: (textKey: string) => void
}

const TextEditor = ({textKey, onTextKeyChange}: TextEditorProps) => {
  const {hebrew, english, update} = useLiveString(textKey)

  const onTextValueChange =
    (language: Language) => (event: React.ChangeEvent<HTMLInputElement>) => {
      update(language, event.target.value)
    }

  const onKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTextKeyChange(event.target.value)
  }

  return (
    <Stack spacing="16px">
      <EditorSubHeader name="Text" />
      <TextField
        id="outlined-name"
        variant="standard"
        label="Text Key"
        value={textKey}
        onChange={onKeyChange}
      />
      {!!textKey && (
        <Stack spacing="8px">
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Hebrew</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                inputProps={{dir: 'auto'}}
                fullWidth
                multiline
                sx={{textAlign: 'right'}}
                id="outlined-hebrew"
                variant="outlined"
                label="Hebrew"
                value={hebrew}
                onChange={onTextValueChange('hebrew')}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion expanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>English</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                fullWidth
                multiline
                id="outlined-english"
                variant="outlined"
                label="English"
                value={english}
                onChange={onTextValueChange('english')}
              />
            </AccordionDetails>
          </Accordion>
        </Stack>
      )}
    </Stack>
  )
}

interface StyleEditorProps {
  styles?: TextStyle[]
  onChange: (styles: TextStyle[]) => void
}

const StyleEditor = ({styles = [], onChange}: StyleEditorProps) => {
  const onStylePicked =
    (index: number) => (event: SelectChangeEvent<StyleTypes>) => {
      const copiedStyles = [...styles]
      copiedStyles[index] = {
        ...styles[index],
        name: event.target.value as StyleTypes,
      }
      onChange(copiedStyles)
    }

  const onPositionPicked =
    (index: number) => (event: SelectChangeEvent<StylePosition>) => {
      const copiedStyles = [...styles]
      copiedStyles[index] = {
        ...styles[index],
        position: event.target.value as StylePosition,
      }
      onChange(copiedStyles)
    }

  const onDeleteStyle = (index: number) => () => {
    const copiedStyles = [...styles]
    copiedStyles.splice(index, 1)
    onChange(copiedStyles)
  }

  const addStyle = () => {
    onChange([...styles, {name: 'Bold', position: 'All', words: 1}])
  }

  const onWordsValueChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = +event.target.value
      if (isNaN(value)) {
        Log.w(TAG, 'word value is not a number')
        return
      }
      const copiedStyles = [...styles]
      copiedStyles[index] = {
        ...styles[index],
        words: value,
      }
      onChange(copiedStyles)
    }

  return (
    <Stack>
      <EditorSubHeader name="Styles" />
      {styles.map(({name, position, words}, index) => (
        <Stack direction="row">
          <Select
            label="Style Type"
            value={name}
            onChange={onStylePicked(index)}
          >
            {StyleTypes.map((styleType) => (
              <MenuItem value={styleType}>{styleType}</MenuItem>
            ))}
          </Select>
          <Select
            label="Position"
            value={position}
            onChange={onPositionPicked(index)}
          >
            {StylePosition.map((p) => (
              <MenuItem value={p}>{p}</MenuItem>
            ))}
          </Select>
          {position !== 'All' && (
            <TextField
              id="outlined-hebrew"
              variant="outlined"
              label="Words"
              value={words}
              onChange={onWordsValueChange(index)}
            />
          )}
          <IconButton aria-label="delete" onClick={onDeleteStyle(index)}>
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}
      <Button onClick={addStyle} variant="text">
        Add Style
      </Button>
    </Stack>
  )
}
