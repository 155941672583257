import {Divider, Typography, Stack, Button} from '@mui/material'
import {useEditContext} from 'edit/edit.context'
import {removeLastItem} from 'utils'

interface Props {
  name: string
}

export const EditorSubHeader = ({name}: Props) => {
  return (
    <Stack sx={{marginTop: '16px'}}>
      <Typography
        sx={{fontWeight: 'bold'}}
        color="primary"
        display="block"
        variant="subtitle1"
      >
        {name}
      </Typography>
      <Divider sx={{marginBottom: '8px'}} />
    </Stack>
  )
}

export const EditorHeader = ({name}: Props) => {
  const {breadcrumbs, goBack, setBreadcrumbs} = useEditContext()

  const onBreadcrumbClick = (index: number) => () => {
    setBreadcrumbs(breadcrumbs.slice(0, index + 1))
  }

  return (
    <Stack sx={{marginBottom: '16px'}}>
      {breadcrumbs.length > 1 && (
        <Stack direction="row">
          {removeLastItem(breadcrumbs)?.map((config, index) => (
            <Button onClick={onBreadcrumbClick(index)} variant="text">
              {config.type}: {config.key}/
            </Button>
          ))}
        </Stack>
      )}
      <Typography
        sx={{fontWeight: 'bold', paddingTop: '16px', paddingBottom: '16px'}}
        color="text.primary"
        display="block"
        variant="h4"
      >
        {name}
      </Typography>
    </Stack>
  )
}
