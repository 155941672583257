import {useFirebase} from 'firebase/firebaseRoot'
import {ref, onValue} from 'firebase/database'
import {filterNonNull, isNullOrEmpty, Log} from 'utils'
import {useEffect, useMemo, useRef, useState} from 'react'
import {Category} from 'database/models/category.model'
import {Tefila} from 'database/models/tefila.model'
import {FirebaseDBSchema, KeylessVersion} from 'database/models/dbSchema.model'
import {
  getPathForType,
  getPathForTranslation,
  getPathForKeyInType,
} from 'database/dbUtils'
import {Unsubscribe} from '@firebase/util'
import {
  getCertainValueForNusach,
  getValueForNusach,
  Language,
  Section,
  TextElement,
  TextGroup,
} from 'models'
import {TextRefrenceElement} from 'database/models/textReference.model'
import {useSiddurContext} from 'siddurContext/siddurRoot'

const TAG = 'FirebaseProvider'

type WatchMap = Record<string, Unsubscribe>

const unsubAll = (watches: WatchMap) =>
  Object.keys(watches).forEach((k) => {
    if (watches[k]) watches[k]()
  })

export const useTranslations = () => {
  const {db} = useFirebase()
  const [cached, setCached] = useState<
    Record<Language, Record<string, string>>
  >({hebrew: {}, english: {}, transliterated: {}})
  const [watching, setWatching] = useState<WatchMap>({})

  useEffect(() => {
    return () => {
      unsubAll(watching)
    }
  }, [])

  const watchString = (key: string, translation: Language = 'hebrew') => {
    const watchKey = key + translation
    if (watching[watchKey]) return
    const unsub = onValue(
      ref(db, getPathForTranslation(translation, key)),
      (snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val() as string
          setCached((c) => {
            const updatedCache = {...c}
            const updatedTranslation = {...c[translation]}
            updatedTranslation[key] = val
            updatedCache[translation] = updatedTranslation
            return updatedCache
          })
        }
      },
    )
    setWatching((w) => {
      const newWatching = {...w}
      newWatching[watchKey] = unsub
      return newWatching
    })
  }

  return {watchString, cached}
}

export const useFirebaseDb = () => {
  const {db} = useFirebase()
  const {nusach} = useSiddurContext()

  const [categories, setCategories] = useState<Category[]>([])
  const [tefilos, setTefilot] = useState<Tefila[]>([])
  const [sections, setSections] = useState<Section[]>([])
  const [textGroups, setTextGroups] = useState<TextGroup[]>([])
  const [textElements, setTextElements] = useState<TextElement[]>([])
  const done = useRef(false)

  const readValue = <
    T extends keyof FirebaseDBSchema,
    P extends keyof FirebaseDBSchema[T],
  >(
    onGetValue: (value: T) => void,
    type: T,
    key?: P,
  ) =>
    onValue(ref(db, getPathForType(type)), (snapshot) => {
      if (snapshot.exists()) {
        onGetValue(snapshot.val())
      } else {
        Log.e(TAG, 'no value for: ', type, key)
      }
    })

  useEffect(() => {
    if (!db) return
    readValue((cats) => {
      setCategories(
        Object.keys(cats).reduce((acc, categoryKey) => {
          return [...acc, {...cats[categoryKey], key: categoryKey}]
        }, [] as Category[]),
      )
    }, 'categories')

    readValue((tefs) => {
      setTefilot(
        Object.keys(tefs).reduce(
          (acc, key) => [...acc, {...tefs[key], key}],
          [] as Tefila[],
        ),
      )
    }, 'tefilot')
  }, [db])

  const watchValue = <
    T extends TextGroup | Section | TextElement | TextRefrenceElement,
  >(
    type: keyof FirebaseDBSchema,
    key: string,
    onUpdate: (update: T) => void,
  ) => {
    return new Promise<T | undefined>((resolve) => {
      onValue(ref(db, getPathForKeyInType(type, key)), (snapshot) => {
        if (snapshot.exists()) {
          const val = snapshot.val() as T
          if (!done.current) resolve({...val, key})
          else onUpdate({...val, key})
        } else {
          resolve(undefined)
        }
      })
    })
  }

  const watchTefila = ({sectionIds}: Tefila) => {
    done.current = false
    setTextElements([])
    setSections([])
    setTextGroups([])
    Promise.all(
      getCertainValueForNusach([], nusach, sectionIds).map(watchSection),
    ).then((secs) => {
      setSections(filterNonNull(secs))
    })
  }

  const watchSection = (sectionKey: string) =>
    watchValue<Section>('sections', sectionKey, (updated) => {
      if (done.current)
        setSections((s) => [
          ...s.filter(({key}) => key !== updated.key),
          updated,
        ])
    })

  const watchTextGroup = (key: string) =>
    watchValue<TextGroup>('text-groups', key, (updated) => {
      if (done.current)
        setTextGroups((s) => [
          ...s.filter(({key: groupKey}) => groupKey !== updated.key),
          updated,
        ])
    })

  const watchTextElement = (key: string) =>
    watchValue<TextElement | TextRefrenceElement>(
      'text-elements',
      key,
      (updated) => {
        if (done.current)
          setTextElements((s) => [
            ...s.filter(
              ({key: elementKey}) =>
                elementKey !== (updated as TextElement).key,
            ),
            updated as TextElement,
          ])
      },
    )

  useEffect(() => {
    Promise.all(
      sections.reduce(
        (acc, s) => [
          ...acc,
          ...getCertainValueForNusach([], nusach, s.textGroupIds).map(
            watchTextGroup,
          ),
        ],
        [] as Promise<TextGroup | undefined>[],
      ),
    ).then((tGs) => {
      setTextGroups(filterNonNull(tGs))
    })
  }, [sections])

  useEffect(() => {
    Promise.all(
      textGroups.reduce((acc, g) => {
        acc = [
          ...acc,
          ...getCertainValueForNusach([], nusach, g.elements).map(
            watchTextElement,
          ),
        ]
        return acc
      }, [] as Promise<TextElement | TextRefrenceElement | undefined>[]),
    ).then((elements) => {
      setTextElements(filterNonNull(elements) as TextElement[])
      done.current = !isNullOrEmpty(filterNonNull(elements))
    })
  }, [textGroups])

  return {
    categories: categories.sort((c1, c2) => c1.order - c2.order),
    tefilos,
    sections,
    textGroups,
    textElements,
    watchTefila,
  }
}
