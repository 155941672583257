import {
  onValue,
  ref,
  set,
  query,
  equalTo,
  update as fbUpdate,
  orderByChild,
} from '@firebase/database'
import {getPathForTranslation, getPathForType} from 'database/dbUtils'
import {FirebaseDBSchema} from 'database/models/dbSchema.model'
import {useFirebase} from 'firebase/firebaseRoot'
import {Language} from 'models'
import {useEffect, useState} from 'react'
import {Log} from 'utils'

const TAG = 'LiveStringProvider'
interface UpdateProps {
  resolveKey?: string
  translation: Language
  newValue: string
  type: keyof FirebaseDBSchema
  valueKey: string
}

export const useLiveString = (key?: string) => {
  const [hebrew, setHebrew] = useState<string>()
  const [english, setEnglish] = useState<string>()
  const [transliterated, setTransliterated] = useState<string>()
  const {db} = useFirebase()

  useEffect(() => {
    if (!key) return
    onValue(
      ref(db, getPathForTranslation('english', key)),
      (englishSnapshot) => {
        if (englishSnapshot.exists()) setEnglish(englishSnapshot.val())
        else {
          setEnglish('no value')
        }
      },
    )
    onValue(ref(db, getPathForTranslation('hebrew', key)), (hebrewSnapshot) => {
      if (hebrewSnapshot.exists()) setHebrew(hebrewSnapshot.val())
      else {
        setHebrew('no value')
      }
    })
  }, [db, key])

  const updateUsages = ({
    newValue,
    type,
    valueKey,
    resolveKey = 'resolvedName',
    translation,
  }: UpdateProps) => {
    const updateObject = {}
    updateObject[`${resolveKey}/${translation}`] = newValue
    if (!key) return
    onValue(
      query(
        ref(db, getPathForType(type)),
        orderByChild(valueKey),
        equalTo(key),
      ),
      (v) => {
        v.forEach((r) => {
          fbUpdate(r.ref, updateObject)
        })
      },
      {onlyOnce: true},
    )
  }

  const update = (translation: Language, newValue: string) => {
    if (!key) return
    set(ref(db, getPathForTranslation(translation, key)), newValue)
    ref(db, getPathForType('tefilot'))
    const shared = {
      newValue,
      translation,
      resolveKey: 'resolvedName',
      valueKey: 'name',
    }
    updateUsages({...shared, type: 'categories'})
    updateUsages({...shared, type: 'tefilot'})
    updateUsages({...shared, type: 'sections'})
    updateUsages({
      ...shared,
      type: 'text-elements',
      resolveKey: 'resolvedText',
      valueKey: 'text',
    })
  }
  return {hebrew, english, transliterated, update}
}
