import {
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
} from '@mui/material'
import {Nusach} from 'models'
import {useSiddurContext} from 'siddurContext/siddurRoot'

export const Settings = () => {
  const {
    laterAdditions,
    setLaterAdditions,
    nusach,
    setNusach,
    sfardAdditions,
    setSfardAdditions,
  } = useSiddurContext()

  const handleSwitchChange =
    (dispatch: (enabled: boolean) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(event.target.checked)
    }

  const handleNusachChange = (event: SelectChangeEvent) => {
    setNusach(event.target.value as Nusach)
  }

  return (
    <Stack>
      <FormGroup>
        <FormControl fullWidth>
          <InputLabel id="nusach-select-label">נוסח</InputLabel>
          <Select
            labelId="nusach-select-label"
            id="nusach-select"
            value={nusach}
            label="Nusach"
            onChange={handleNusachChange}
          >
            {Nusach.map((n) => (
              <MenuItem value={n}>{n}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Switch
              checked={laterAdditions}
              onChange={handleSwitchChange(setLaterAdditions)}
            />
          }
          label="Later Additions"
        />
        <FormControlLabel
          control={
            <Switch
              checked={sfardAdditions}
              onChange={handleSwitchChange(setSfardAdditions)}
            />
          }
          label="Sfard Additions"
        />
      </FormGroup>
    </Stack>
  )
}
