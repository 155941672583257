import {typeguardGenerator} from '../utils'

export const BookKey = [
  'amos',
  'bamidbar',
  'breishis',
  'chabakuk',
  'chagai',
  'daniel',
  'diverei-hayamim-a',
  'diverei-hayamim-b',
  'dvarim',
  'eicha',
  'esther',
  'ezra',
  'hoshea',
  'iyov',
  'koheles',
  'malachai',
  'melachim-a',
  'melachim-b',
  'micah',
  'mishlei',
  'nachum',
  'nechemia',
  'ovadiah',
  'rus',
  'shir-hashirim',
  'shmos',
  'shmuel-a',
  'shmuel-b',
  'shoftim',
  'tehilim',
  'vayikra',
  'yechezkel',
  'yehoshua',
  'yirmyahu',
  'yishaiahu',
  'yoel',
  'yonah',
  'zechariah',
  'zephaniah',
] as const
export type BookKey = typeof BookKey[number]
export function isBookKey(value: unknown): value is BookKey {
  return typeguardGenerator(BookKey)(value)
}

export const bookNames: Record<BookKey, Record<'hebrew', string>> = {
  amos: {hebrew: 'עמוס'},
  bamidbar: {hebrew: 'במדבר'},
  breishis: {hebrew: 'בראשית'},
  chabakuk: {hebrew: 'חבקוק'},
  chagai: {hebrew: 'חגי'},
  daniel: {hebrew: 'דניאל'},
  'diverei-hayamim-a': {hebrew: 'דברי הימים א'},
  'diverei-hayamim-b': {hebrew: 'דברי הימים ב'},
  dvarim: {hebrew: 'דברים'},
  eicha: {hebrew: 'איכה'},
  esther: {hebrew: 'אסתר'},
  ezra: {hebrew: 'עזרא'},
  hoshea: {hebrew: 'הושע'},
  iyov: {hebrew: 'איוב'},
  koheles: {hebrew: 'קהלת'},
  malachai: {hebrew: 'מלאכי'},
  'melachim-a': {hebrew: 'מלכים א'},
  'melachim-b': {hebrew: 'מלכים ב'},
  micah: {hebrew: 'מיכה'},
  mishlei: {hebrew: 'משלי'},
  nachum: {hebrew: 'נחום'},
  nechemia: {hebrew: 'נחמיה'},
  ovadiah: {hebrew: 'עובדיה'},
  rus: {hebrew: 'רות'},
  'shir-hashirim': {hebrew: 'שיר השירים'},
  shmos: {hebrew: 'שמות'},
  'shmuel-a': {hebrew: 'שמואל א'},
  'shmuel-b': {hebrew: 'שמואל ב'},
  shoftim: {hebrew: 'שופטים'},
  tehilim: {hebrew: 'תהילים'},
  vayikra: {hebrew: 'ויקרא'},
  yechezkel: {hebrew: 'יחזקאל'},
  yehoshua: {hebrew: 'יהושע'},
  yirmyahu: {hebrew: 'ירמיהו'},
  yishaiahu: {hebrew: 'ישעיהו'},
  yoel: {hebrew: 'יואל'},
  yonah: {hebrew: 'יונה'},
  zechariah: {hebrew: 'זכריה'},
  zephaniah: {hebrew: 'צפניה'},
}

export type SpecialBook = 'parsha' | 'haftara' | 'pirkeiAvos'
export type BookItemKey = SpecialBook | BookKey

export const ShnayimMikraBreakType = ['none', 'pasuk', 'aliah'] as const
export type ShnayimMikraBreakType = typeof ShnayimMikraBreakType[number]
// export const isShanyimBreakMikraType = typeguardGenerator(ShnayimMikraBreakType)

export const ShnayimMikraSortType = ['ppt', 'ptp'] as const
export type ShnayimMikraSortType = typeof ShnayimMikraSortType[number]
// export const isShnayimMikraSortType = typeguardGenerator(ShnayimMikraSortType)

const shnayimMikraBreakTypeNames: Record<
  ShnayimMikraBreakType,
  Record<'hebrew', string>
> = {
  none: {hebrew: 'ללא'},
  aliah: {hebrew: 'עליה'},
  pasuk: {hebrew: 'פסוק'},
}

const shnayimMikraSortTypeNames: Record<
  ShnayimMikraSortType,
  Record<'hebrew', string>
> = {
  ppt: {hebrew: 'תרגום בסוף'},
  ptp: {hebrew: 'תרגום בעמצע'},
}

export const getNameForShnayimMikraBreakType = (type: ShnayimMikraBreakType) =>
  shnayimMikraBreakTypeNames[type].hebrew

export const getNameForShnayimMikraSortType = (type: ShnayimMikraSortType) =>
  shnayimMikraSortTypeNames[type].hebrew

const specialBookNames: Record<SpecialBook, Record<'hebrew', string>> = {
  haftara: {hebrew: 'הפטרות'},
  parsha: {hebrew: 'פרשה'},
  pirkeiAvos: {hebrew: 'פרקי אבות'},
}

export interface BookList {
  title: string
  books: BookItemKey[]
}

export const getNameForBook = (book: BookItemKey) =>
  isBookKey(book) ? bookNames[book].hebrew : specialBookNames[book].hebrew
