import {filterNonNull} from 'utils'

export const Holiday = {
  RoshHashana1: 'Rosh Hashana 1',
  RoshHashana2: 'RoshHashana2',
  ShabbosShuva: 'ShabbatShuva',
  TzomGedaliah: 'Tzom Gedaliah',
  ErevYomKippur: 'Erev Yom Kippur',
  YomKippur: 'Yom Kippur',
  ErevSuccos: 'Erev Sukkot',
  Succos: 'Sukkot',
  ShminiAtzeres: 'Shmini Atzeret',
  SimchasTorah: 'Simchat Torah',
  ShabbosMevarchim: 'Shabbat Mevarchim',
  RoshChodesh: 'Rosh Chodesh',
  ErevChanuka: 'Erev Chanukah',
  Chanukah: 'Chanukah',
  TzomTeives: "Asara B'Tevet",
  TuBsvhat: "Tu B'Shvat",
  TuBav: "Tub B'Av",
  ShabbosShkalim: 'Shabbat Shekalim',
  TaanisEsther: "Ta'anit Esther",
  ShabbosZachor: 'Shabbat Zachor',
  PurimKatan: 'Purim Katan',
  Purim: 'Purim',
  ShushanPurim: 'Shushan Purim',
  ShabbosParah: 'Shabbat Parah',
  ShabbosHaChodesh: 'Shabbat Hachodesh',
  ShabbosHaGadol: 'Shabbat HaGadol',
  ErevPesach: 'Erev Pesach',
  Pesach: 'Pesach:',
  YomHaShoah: 'Yom HaShoah',
  YomHaZikaron: 'Yom HaZikaron',
  YomHaAtzmaut: "Yom HaAtzma'ut",
  PesachSheini: 'Pesach Sheni',
  LagBomer: "Lag B'Omer",
  YomYerushaleim: 'Yom Yerushalayim',
  ErevShavuos: 'Erev Shavuot',
  Shavuos: 'Shavuot',
  TzomTaamuz: "Shiva-Asar B'Tamuz",
  ShabbosChazon: 'Shabbat Chazon',
  ErevTishaBav: "Erev Tish'a B'Av",
  TishaBav: "Tish'a B'Av",
  ShabbosNachamu: 'Shabbat Nachamu',
  LeilSelichos: 'Leil Selichot',
  ErevRoshHashana: 'Erev Rosh Hashana',
  Sigd: 'sigd',
} as const
export type Holiday = typeof Holiday[keyof typeof Holiday]

export const hebcalHoliday = [
  'Rosh Hashana',
  'Rosh Hashana II',
  'Tzom Gedaliah',
  'Shabbat Shuva',
  'Erev Yom Kippur',
  'Yom Kippur',
  'Erev Sukkot',
  'Sukkot I',
  'Sukkot II',
  "Sukkot II (CH''M)",
  "Sukkot III (CH''M)",
  "Sukkot IV (CH''M)",
  "Sukkot V (CH''M)",
  "Sukkot VI (CH''M)",
  'Sukkot VII (Hoshana Raba)',
  'Shmini Atzeret',
  'Simchat Torah',
  'Shabbat Mevarchim Chodesh Cheshvan',
  'Rosh Chodesh Cheshvan',
  'Rosh Chodesh Cheshvan',
  'Yom HaAliyah School Observance',
  'Shabbat Mevarchim Chodesh Kislev',
  'Sigd',
  'Rosh Chodesh Kislev',
  'Shabbat Mevarchim Chodesh Tevet',
  'Chanukah: 1 Candle',
  'Chanukah: 2 Candles',
  'Chanukah: 3 Candles',
  'Chanukah: 4 Candles',
  'Chanukah: 5 Candles',
  'Chanukah: 6 Candles',
  'Chanukah: 7 Candles',
  'Rosh Chodesh Tevet',
  'Chanukah: 8 Candles',
  'Rosh Chodesh Tevet',
  'Chanukah: 8th Day',
  "Asara B'Tevet",
  "Shabbat Mevarchim Chodesh Sh'vat",
  "Rosh Chodesh Sh'vat",
  'Shabbat Shirah',
  'Tu BiShvat',
  'Shabbat Mevarchim Chodesh Adar I',
  'Rosh Chodesh Adar I',
  'Rosh Chodesh Adar I',
  'Purim Katan',
  'Shabbat Shekalim',
  'Shabbat Mevarchim Chodesh Adar II',
  'Rosh Chodesh Adar II',
  'Rosh Chodesh Adar',
  'Shabbat Zachor',
  "Ta'anit Esther",
  'Erev Purim',
  'Purim',
  'Shushan Purim',
  'Shabbat Parah',
  'Shabbat Mevarchim Chodesh Nisan',
  'Shabbat HaChodesh',
  'Rosh Chodesh Nisan',
  'Shabbat HaGadol',
  'Yom HaAliyah',
  "Ta'anit Bechorot",
  'Erev Pesach',
  'Pesach I',
  'Pesach II',
  "Pesach II (CH''M)",
  "Pesach III (CH''M)",
  "Pesach IV (CH''M)",
  "Pesach V (CH''M)",
  "Pesach VI (CH''M)",
  'Pesach VII',
  'Pesach VIII',
  'Yom HaShoah',
  'Shabbat Mevarchim Chodesh Iyyar',
  'Rosh Chodesh Iyyar',
  'Rosh Chodesh Iyyar',
  'Yom HaZikaron',
  "Yom HaAtzma'ut",
  'Pesach Sheni',
  'Lag BaOmer',
  'Shabbat Mevarchim Chodesh Sivan',
  'Yom Yerushalayim',
  'Rosh Chodesh Sivan',
  'Erev Shavuot',
  'Shavuot',
  'Shabbat Mevarchim Chodesh Tamuz',
  'Rosh Chodesh Tamuz',
  'Rosh Chodesh Tamuz',
  'Tzom Tammuz',
  'Shabbat Mevarchim Chodesh Av',
  'Rosh Chodesh Av',
  'Shabbat Chazon',
  "Erev Tish'a B'Av",
  "Tish'a B'Av",
  "Tu B'Av",
  'Shabbat Nachamu',
  'Shabbat Mevarchim Chodesh Elul',
  'Rosh Chodesh Elul',
  'Rosh Hashana LaBehemot',
  'Rosh Chodesh Elul',
  'Leil Selichot',
  'Erev Rosh Hashana',
] as const
export type HebcalHoliday = typeof hebcalHoliday[number]

const hebcalHolidayMap: Record<HebcalHoliday, Holiday | undefined> = {
  "Asara B'Tevet": Holiday.TzomTeives,
  'Lag BaOmer': Holiday.LagBomer,
  'Leil Selichot': Holiday.LeilSelichos,
  'Pesach Sheni': Holiday.PesachSheini,
  'Rosh Chodesh Av': Holiday.RoshChodesh,
  'Rosh Chodesh Cheshvan': Holiday.RoshChodesh,
  'Rosh Chodesh Elul': Holiday.RoshChodesh,
  'Rosh Chodesh Iyyar': Holiday.RoshChodesh,
  'Rosh Chodesh Kislev': Holiday.RoshChodesh,
  'Rosh Chodesh Nisan': Holiday.RoshChodesh,
  "Rosh Chodesh Sh'vat": Holiday.RoshChodesh,
  'Rosh Chodesh Sivan': Holiday.RoshChodesh,
  'Rosh Chodesh Tamuz': Holiday.RoshChodesh,
  'Rosh Chodesh Tevet': Holiday.RoshChodesh,
  'Rosh Chodesh Adar I': Holiday.RoshChodesh,
  'Rosh Chodesh Adar II': Holiday.RoshChodesh,
  'Rosh Chodesh Adar': Holiday.RoshChodesh,
  'Rosh Hashana LaBehemot': undefined,
  'Rosh Hashana II': Holiday.RoshHashana2,
  'Shabbat Chazon': Holiday.ShabbosChazon,
  'Shabbat HaChodesh': Holiday.ShabbosHaChodesh,
  'Shabbat HaGadol': Holiday.ShabbosHaGadol,
  'Shabbat Nachamu': Holiday.ShabbosNachamu,
  'Shabbat Parah': Holiday.ShabbosParah,
  'Shabbat Shekalim': Holiday.ShabbosShkalim,
  'Shabbat Shirah': undefined,
  'Shabbat Shuva': Holiday.ShabbosShuva,
  'Shabbat Zachor': Holiday.ShabbosZachor,
  'Shushan Purim': Holiday.ShushanPurim,
  "Ta'anit Bechorot": Holiday.ErevPesach,
  "Ta'anit Esther": Holiday.TaanisEsther,
  "Tish'a B'Av": Holiday.TishaBav,
  "Tu B'Av": Holiday.TuBav,
  'Tu BiShvat': Holiday.TuBsvhat,
  'Tzom Gedaliah': Holiday.TzomGedaliah,
  'Tzom Tammuz': Holiday.TzomTaamuz,
  'Yom HaAliyah School Observance': undefined,
  'Yom HaAliyah': undefined,
  "Yom HaAtzma'ut": Holiday.YomHaAtzmaut,
  'Yom HaShoah': Holiday.YomHaShoah,
  'Yom HaZikaron': Holiday.YomHaZikaron,
  'Yom Kippur': Holiday.YomKippur,
  'Yom Yerushalayim': Holiday.YomYerushaleim,
  'Chanukah: 1 Candle': Holiday.ErevChanuka,
  'Chanukah: 2 Candles': Holiday.Chanukah,
  'Chanukah: 3 Candles': Holiday.Chanukah,
  'Chanukah: 4 Candles': Holiday.Chanukah,
  'Chanukah: 5 Candles': Holiday.Chanukah,
  'Chanukah: 6 Candles': Holiday.Chanukah,
  'Chanukah: 7 Candles': Holiday.Chanukah,
  'Chanukah: 8 Candles': Holiday.Chanukah,
  'Chanukah: 8th Day': Holiday.Chanukah,
  'Pesach I': Holiday.Pesach,
  "Pesach II (CH''M)": Holiday.Pesach,
  "Pesach III (CH''M)": Holiday.Pesach,
  "Pesach IV (CH''M)": Holiday.Pesach,
  "Pesach V (CH''M)": Holiday.Pesach,
  "Pesach VI (CH''M)": Holiday.Pesach,
  'Pesach VII': Holiday.Pesach,
  'Pesach VIII': Holiday.Pesach,
  Purim: Holiday.Purim,
  Shavuot: Holiday.Shavuos,
  'Sukkot I': Holiday.Succos,
  'Sukkot II': Holiday.Succos,
  "Sukkot II (CH''M)": Holiday.Succos,
  "Sukkot III (CH''M)": Holiday.Succos,
  "Sukkot IV (CH''M)": Holiday.Succos,
  "Sukkot V (CH''M)": Holiday.Succos,
  "Sukkot VI (CH''M)": Holiday.Succos,
  'Sukkot VII (Hoshana Raba)': Holiday.Succos,
  'Shmini Atzeret': Holiday.ShminiAtzeres,
  'Simchat Torah': Holiday.SimchasTorah,
  Sigd: Holiday.Sigd,
  'Erev Pesach': Holiday.ErevPesach,
  'Erev Purim': undefined,
  'Erev Rosh Hashana': Holiday.ErevRoshHashana,
  'Erev Shavuot': Holiday.ErevShavuos,
  'Erev Sukkot': Holiday.ErevSuccos,
  "Erev Tish'a B'Av": Holiday.ErevTishaBav,
  'Erev Yom Kippur': Holiday.ErevYomKippur,
  'Pesach II': Holiday.Pesach,
  'Purim Katan': Holiday.PurimKatan,
  'Rosh Hashana': Holiday.RoshHashana1,
  'Shabbat Mevarchim Chodesh Adar I': undefined,
  'Shabbat Mevarchim Chodesh Adar II': undefined,
  'Shabbat Mevarchim Chodesh Nisan': undefined,
  'Shabbat Mevarchim Chodesh Iyyar': undefined,
  'Shabbat Mevarchim Chodesh Sivan': undefined,
  'Shabbat Mevarchim Chodesh Tamuz': undefined,
  'Shabbat Mevarchim Chodesh Av': undefined,
  'Shabbat Mevarchim Chodesh Elul': undefined,
  'Shabbat Mevarchim Chodesh Cheshvan': undefined,
  'Shabbat Mevarchim Chodesh Kislev': undefined,
  'Shabbat Mevarchim Chodesh Tevet': undefined,
  "Shabbat Mevarchim Chodesh Sh'vat": undefined,
}

export const hebcalHolidayToHoliday = (h: HebcalHoliday) => hebcalHolidayMap[h]

export const hebcalHolidaysToHolidays = (h: HebcalHoliday[]) =>
  filterNonNull(h.map(hebcalHolidayToHoliday))
