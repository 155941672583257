import React, {useState} from 'react'
import './App.css'
import {FirebaseRoot} from './firebase/firebaseRoot'
import {AppBar} from 'appBar/appBar.widget'
import {CategoriesList} from 'categoriesList/catoriesList.wiget'
import {Box, Container, Paper, Stack} from '@mui/material'
import {EditRoot, useEditContext} from 'edit/edit.context'
import {EditPresenter} from 'edit/edit.presenter'
import {SiddurRoot} from 'siddurContext/siddurRoot'
import {TefilaPresenter} from 'siddurContext/tefilaPresenter.component'
import {resolvedBackToFb} from 'database/dbUtils'
import {ThemeRoot} from 'theme/themeProvider'
import {useScreenSize} from 'theme/smallScreen.hook'
import {AppBarDrawer} from 'appBar/appBarDrawer.widget'
import {LuachHayom} from 'siddurContext/luach.component'
import {Settings} from 'siddurContext/settings.component'

function App() {
  return (
    <FirebaseRoot>
      <SiddurRoot>
        <ThemeRoot>
          <EditRoot>
            <AppMainContent />
          </EditRoot>
        </ThemeRoot>
      </SiddurRoot>
    </FirebaseRoot>
  )
}

export default App

const AppMainContent = () => {
  const {editEnabled} = useEditContext()
  const {isMobile} = useScreenSize()

  return (
    <>
      {!isMobile && <AppBar />}
      {isMobile && <AppBarDrawer />}
      <Container sx={{marginTop: '16px', flex: 1}}>
        {!isMobile && (
          <Stack direction="row" spacing="16px">
            <ContentWrapper>
              <CategoriesList />
            </ContentWrapper>
            {editEnabled && (
              <ContentWrapper wide>
                <EditPresenter />
              </ContentWrapper>
            )}
            <ContentWrapper wide>
              <LuachHayom />
              <TefilaPresenter />
            </ContentWrapper>
            <ContentWrapper wide>
              <Settings />
            </ContentWrapper>
          </Stack>
        )}
        {isMobile && (
          <Box sx={{height: '90vh', overflowY: 'scroll'}}>
            <LuachHayom />
            <TefilaPresenter />
          </Box>
        )}
      </Container>
    </>
  )
}

const ContentWrapper = ({
  children,
  wide,
}: {
  children: React.ReactNode
  wide?: boolean
}) => (
  <Paper elevation={4}>
    <Box
      sx={{
        height: '100vh',
        overflowY: 'scroll',
        paddingBottom: '36px',
        width: wide ? '650px' : '180px',
      }}
    >
      {children}
    </Box>
  </Paper>
)

export const SaveList = () => {
  const data = new Blob([JSON.stringify(resolvedBackToFb())], {
    type: 'text/plain',
  })

  const downloadLink = window.URL.createObjectURL(data)

  return (
    <a
      // this attribute sets the filename
      download="list.json"
      // link to the download URL
      href={downloadLink}
    >
      download
    </a>
  )
}
