import {isDebug} from '../config'

type Logger = (message?: any, ...payload: any[]) => void

export enum LogColors {
  Reset = '\x1b[0m',
  FgBlack = '\x1b[30m',
  FgRed = '\x1b[31m',
  FgGreen = '\x1b[32m',
  FgYellow = '\x1b[33m',
  FgBlue = '\x1b[34m',
  FgMagenta = '\x1b[35m',
  FgCyan = '\x1b[36m',
  FgWhite = '\x1b[37m',
  BgBlack = '\x1b[40m',
  BgRed = '\x1b[41m',
  BgGreen = '\x1b[42m',
  BgYellow = '\x1b[43m',
  BgBlue = '\x1b[44m',
  BgMagenta = '\x1b[45m',
  BgCyan = '\x1b[46m',
  BgWhite = '\x1b[47m',
  Bold = '\x1b[1m',
}

const defaultTagStyle = LogColors.BgWhite + LogColors.FgBlue

const log =
  (
    logger: Logger,
    style: string,
    tagStyle: string = defaultTagStyle,
    prefix: string = '',
  ) =>
  (message?: any, ...payload: any[]) => {
    if (isDebug) {
      const isDebuggerAttached =
        typeof global !== 'undefined' &&
        Object.prototype.toString.call(global) === '[object global]'

      if (isDebuggerAttached) {
        logger(message, ...payload)
      } else {
        logger(
          tagStyle +
            // LogColors.Bold +
            ' ' +
            message +
            ' ' +
            // LogColors.Reset +
            style +
            prefix,
          ...payload,
          // LogColors.Reset,
        )
      }
    }
  }

export const Log = {
  d: log(console.log, LogColors.FgCyan),
  s: log(console.log, LogColors.FgGreen),
  i: log(
    console.info,
    LogColors.FgMagenta,
    LogColors.FgMagenta + LogColors.BgWhite,
  ),
  e: log(console.error, LogColors.FgRed, LogColors.FgRed + LogColors.BgWhite),
  w: log(console.log, LogColors.FgYellow, LogColors.BgRed + LogColors.FgYellow),
  color: (...colors: LogColors[]) => log(console.log, colors.join()),
}
