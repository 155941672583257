import {typeguardGenerator} from '../utils'

export const Parsha = [
  'Breishis',
  'Noach',
  'LechLecha',
  'Vayera',
  'ChayeiSara',
  'Toldos',
  'Vayetzei',
  'Vayishlach',
  'Vayeshev',
  'Mikeitz',
  'Vayigash',
  'Vayechi',
  'Shmos',
  'Vaera',
  'Bo',
  'Beshalach',
  'Yisro',
  'Mishpatim',
  'Teruma',
  'Tetzaveh',
  'KiSisa',
  'VaYakhel',
  'Pekudei',
  'Vayikra',
  'Tzav',
  'Shmini',
  'Tazria',
  'Metzora',
  'AchreiMos',
  'Kedoshim',
  'Emor',
  'Behar',
  'Bechukosai',
  'Bamidbar',
  'Nasso',
  'Behaloscha',
  'Shlach',
  'Korach',
  'Chukas',
  'Balak',
  'Pinchas',
  'Matos',
  'Masei',
  'Dvarim',
  'Veschanan',
  'Eikev',
  'Reeh',
  'Shoftim',
  'KiSeitzei',
  'KiSavo',
  'Nitzavim',
  'Vayelech',
  'Haazinu',
  'VezosHaBrocha',
  'VaYakhelPekudei',
  'TazriaMetzora',
  'AchreiMosKedoshim',
  'BeharBechukosai',
  'ChukasBalak',
  'MatosMasei',
  'NitzavimVayelech',
] as const
export type Parsha = typeof Parsha[number]

export function isParhios(value: unknown): value is Parsha {
  return typeguardGenerator(Parsha)(value)
}

export const getParshaForIndex = (index: number) =>
  index < Parsha.length - 1 ? Parsha[index] : undefined
export const getParshaIndex = (parsha: Parsha) =>
  Parsha.findIndex((p) => p === parsha)

const namesMap: Record<Parsha, string> = {
  Breishis: 'בראשית',
  Noach: 'נח',
  LechLecha: 'לך לך',
  Vayera: 'וירא',
  ChayeiSara: 'חיי שרה',
  Toldos: 'תולדות',
  Vayetzei: 'ויצא',
  Vayishlach: 'וישלח',
  Vayeshev: 'וישב',
  Mikeitz: 'מקץ',
  Vayigash: 'ויגש',
  Vayechi: 'ויחי',
  Shmos: 'שמות',
  Vaera: 'וארא',
  Bo: 'בא',
  Beshalach: 'בשלח',
  Yisro: 'יתרו',
  Mishpatim: 'משפטים',
  Teruma: 'תרומה',
  Tetzaveh: 'תצוה',
  KiSisa: 'כי תשא',
  VaYakhel: 'ויקהל',
  Pekudei: 'פקודי',
  Vayikra: 'ויקרא',
  Tzav: 'צו',
  Shmini: 'שמיני',
  Tazria: 'תזריע',
  Metzora: 'מצורע',
  AchreiMos: 'אחרי מות',
  Kedoshim: 'קדשים',
  Emor: 'אמור',
  Behar: 'בהר',
  Bechukosai: 'בחקתי',
  Bamidbar: 'במדבר',
  Nasso: 'נשא',
  Behaloscha: 'בהעלתך',
  Shlach: 'שלח לך',
  Korach: 'קורח',
  Chukas: 'חקת',
  Balak: 'בלק',
  Pinchas: 'פינחס',
  Matos: 'מטות',
  Masei: 'מסעי',
  Dvarim: 'דברים',
  Veschanan: 'ואתחנן',
  Eikev: 'עקב',
  Reeh: 'ראה',
  Shoftim: 'שופטים',
  KiSeitzei: 'כי תצא',
  KiSavo: 'כי תבוא',
  Nitzavim: 'נצבים',
  Vayelech: 'וילך',
  Haazinu: 'האזינו',
  VezosHaBrocha: 'וזאת הברכה',
  AchreiMosKedoshim: 'אחרי מות קדשים',
  BeharBechukosai: 'בהר בחקתי',
  ChukasBalak: 'חקת בלק',
  MatosMasei: 'מטות מסעי',
  NitzavimVayelech: 'נצבים וילך',
  TazriaMetzora: 'תזריע מצרע',
  VaYakhelPekudei: 'ויקהל פקודי',
}

export const getNameForParsha = (parsha: Parsha) => namesMap[parsha]
