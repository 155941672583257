import {FormControl, RadioGroup, FormControlLabel, Radio} from '@mui/material'
import {Operators} from 'models'

interface OperatorBoxProps {
  operator?: Operators
  onChange: (operator: Operators) => void
}

export const OperatorBox = ({
  operator = Operators.And,
  onChange,
}: OperatorBoxProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(+(event.target as HTMLInputElement).value as Operators)
  }
  return (
    <FormControl component="fieldset">
      <RadioGroup value={operator} onChange={handleChange} row>
        <FormControlLabel
          value={Operators.And}
          control={<Radio />}
          label="And"
        />
        <FormControlLabel value={Operators.Or} control={<Radio />} label="Or" />
      </RadioGroup>
    </FormControl>
  )
}
