import {
  Button,
  List,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
  IconButton,
  Typography,
  Stack,
  Container,
  Box,
  TextField,
  Alert,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/AddCircle'
import {EditorSubHeader} from 'edit/editorHeader.widget'
import {Nusach, NusachMap} from 'models'
import {useState} from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import {Log} from 'utils'
import {useSiddurContext} from 'siddurContext/siddurRoot'

interface Props {
  title: string
  nusachMap?: NusachMap<string[]>
  onValueChange: (newMap: NusachMap<string[]>) => void
  onItemClick?: (item: string) => void
}

const TAG = 'NusachMapEditor'

const reorder = <T extends any>(
  list: Array<T>,
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const NusachMapEditor = ({
  nusachMap = {ashkenaz: []},
  title,
  onValueChange,
  onItemClick,
}: Props) => {
  const {nusach: rootNusach} = useSiddurContext()
  const [nusach, setNusach] = useState<Nusach>(rootNusach)
  const [newKey, setNewKey] = useState<string>()
  const [oldValue, setOldValue] = useState<NusachMap<string[]>>()

  const onNusachChange = (event: React.MouseEvent<HTMLElement>, n: Nusach) => {
    if (!n) return
    setNusach(n)
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const items = nusachMap[nusach] as string[]
    const reordered = reorder(
      items,
      result.source.index,
      result.destination.index,
    )
    const newMap = {...nusachMap}
    newMap[nusach] = reordered
    onValueChange(newMap)
  }

  const captureOldValue = () => {
    setOldValue(nusachMap)
    setTimeout(() => {
      setOldValue(undefined)
    }, 5000)
  }

  const revertOldValue = () => {
    if (oldValue) onValueChange(oldValue)
  }

  const onCopyAshkenaz = () => {
    captureOldValue()
    const newMap = {...nusachMap}
    newMap[nusach] = nusachMap['ashkenaz']
    onValueChange(newMap)
  }

  const onMirrorAshkenaz = () => {
    if (nusach === 'ashkenaz') return
    const newMap = {...nusachMap}
    captureOldValue()
    newMap[nusach] = 'ashkenaz'
    onValueChange(newMap)
  }

  const _onItemClick = (item: string) => () => {
    if (onItemClick) onItemClick(item)
  }

  const onDeleteItem = (index: number) => () => {
    const newMap = {...nusachMap}
    const newArr = [...(newMap[nusach] as string[])]
    newArr.splice(index, 1)
    newMap[nusach] = newArr
    onValueChange(newMap)
  }

  const addKey = () => {
    if (!newKey) return
    const newMap = {...nusachMap}
    const newArr = [...((newMap[nusach] as string[]) || []), newKey]
    newMap[nusach] = newArr
    onValueChange(newMap)
  }

  return (
    <>
      <EditorSubHeader name={title} />
      <Box sx={{justifyContent: 'center', display: 'flex'}}>
        <ToggleButtonGroup
          color="primary"
          value={nusach}
          exclusive
          onChange={onNusachChange}
        >
          {Nusach.map((n) => (
            <ToggleButton value={n}>{n}</ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Box>
      {nusachMap[nusach] === 'ashkenaz' && (
        <>
          <Typography>Mirroring Ashkenaz</Typography>
          <List>
            {nusachMap['ashkenaz'].map((item) => (
              <ListItem button onClick={_onItemClick(item)}>
                {item}
              </ListItem>
            ))}
          </List>
        </>
      )}
      {Array.isArray(nusachMap[nusach]) && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <List {...provided.droppableProps} ref={provided.innerRef}>
                {(nusachMap[nusach] as string[]).map((item, index) => (
                  <Draggable
                    key={item + index}
                    draggableId={item + index}
                    index={index}
                  >
                    {(innerProvided, innerSnapshot) => (
                      <div
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        <Stack direction="row">
                          <ListItem button onClick={_onItemClick(item)}>
                            {item}
                          </ListItem>
                          <IconButton
                            aria-label="delete"
                            onClick={onDeleteItem(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Stack>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      )}
      {nusachMap[nusach] !== 'ashkenaz' && (
        <>
          <TextField
            id="standard-basic"
            label="Add Key"
            variant="standard"
            value={newKey}
            onChange={(e) => setNewKey(e.target.value)}
          />
          <IconButton aria-label="Add" onClick={addKey}>
            <AddIcon />
          </IconButton>
        </>
      )}
      {nusach !== 'ashkenaz' && (
        <>
          <Button onClick={onCopyAshkenaz}>Copy Ashkenaz</Button>
          <Button onClick={onMirrorAshkenaz}>Mirror Ashkenaz</Button>
        </>
      )}
      {!!oldValue && (
        <Alert
          action={
            <Button color="inherit" size="small" onClick={revertOldValue}>
              UNDO
            </Button>
          }
        >
          Nusach Changed
        </Alert>
      )}
    </>
  )
}
